import InputField from "components/fields/InputField";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import { Navigate } from "react-router-dom";
import {Login} from 'views/admin/GraphQl/SignIn';
import {ClientInfo} from 'views/admin/GraphQl/ClientInfo'

export default function SignIn(props) {

  const [loginInput]= Login();
  const [getClientInfo] = ClientInfo();

  const [ok, setOk] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();


  const validEmail = (e) => {
    var filter =
      /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(e).search(filter) != -1;
  };

  const [login, setLogin] = useState({
    email: "",
    pws: "",
  });
  const [err, setErr] = useState({
    email: "",
    pws: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
   
    if (name == "email") {
      if (!validEmail(value)) {
        setErr((prev) => {
          return { ...prev, [name]: "error" };
        });
      } else {
        setErr((prev) => {
          return { ...prev, [name]: "success" };
        });
      }
    }else{
        if (value.length < 6) {
          setErr((prev) => {
            return { ...prev, [name]: "error" };
          });
    
          return;
        } else {
          setErr((prev) => {
            return { ...prev, [name]: "" };
          });
        }
    }
   
    setLogin((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();

    //if(err.email == "error" || err.pws == "error")return
    //if(login.email.length <= 4 || login.pws.length <= 6 )return
    if (validEmail(err.email)) {
        message.open({
          type: "error",
          content: "Inserisci una email valida",
        });
  
        return;
      }
    if (err.pws == "error" || login.pws.length < 6) {
      message.open({
        type: "error",
        content: "Inserisci una password ",
      });

      return;
    }
    const key = "updatable";

    message.open({
      key,
      type: "loading",
      content: "Richiesta alla connessione...",
    });


    loginInput({
      variables: {
        loginInput: {
          "email": login.email,
          "password": login.pws
        }
      }
    }).then((dataLog) => {
      //Login avvenuto con successo
      // setTimeout(() => {
        
        messageApi.open({
          type: 'loading',
          content: 'Ricerca dei dati...',
          duration: 0,
        });
        getClientInfo({ context: { headers: { 'Authorization': `Bearer ${dataLog.data.clientLogIn.token}` } } }).then(({ data }) => {
          //Login avvenuto con successo
          messageApi.destroy();
          message.open({
            key,
            type: "success",
            content: "Connesso",
            duration: 2,
          });
  
          const log = {
            token: dataLog.data.clientLogIn.token,
            user: data.clientInfoProtected
          }
          
          localStorage.setItem('tk', JSON.stringify(log))
          window.location.href="/admin"
          //setOk(true);
          
        })
        .catch((error) => {
          messageApi.destroy();
          console.error('Si è verificato un errore:', error);
          message.open({
            key,
            type: "error",
            content: error.message,
          });
        // }, 3000);
      });
      
      
    })
    .catch((error) => {
      console.error('Si è verificato un errore:', error);
      message.open({
        key,
        type: "error",
        content: error.message,
      });
    });

  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {ok ? <Navigate to="/admin" /> : ""}
      {contextHolder}
      {/* Sign in section */}
      <form
        onSubmit={onSubmit}
        className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]"
      >
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Accedi
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Inserisci i dati d'accesso ricevuti via email dal team MynuApp
        </p>
        {/* Email */}
        <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            required={true}
            state={err.email}
            onChange={handleChange}
            placeholder="mail@simmmple.com"
            id="email"
            name="email"
            type="text"
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 6 characters"
          id="password"
          state={err.pws}
          type="password"
          required={true}
          name="pws"
          onChange={handleChange}
        />
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Hai dimenticato i dati d'accesso?
          </a>
        </div>
        <button
          onClick={onSubmit}
          disabled={login.pws.length < 6}
          className="disabled:bg-gray-400 linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Accedi
        </button>
      </form>
    </div>
  );
}
