import { gql,useQuery, useMutation } from '@apollo/client';



export function UploadImage(){
    
    return useMutation(gql`mutation UploadFile($file: Upload!) {
        uploadFile(file: $file)
      }`);

}