import { gql,useLazyQuery , useMutation } from '@apollo/client';



export function SponsorCreate(){
    
  return useMutation(gql`mutation Mutation($storeClientSponsorInput: StoreClientSponsorInput) {
    clientSponsorStore(StoreClientSponsorInput: $storeClientSponsorInput) {
      _id
      cliente
      sponsor_name
      sponsor_src
      sponsor_image
      sponsor_image_dump
      type
      createAt
      updateAt
    }
  }`);

}

export function SponsorDelete(){
    
    return useMutation(gql`mutation Mutation($deleteClientSponsorInput: DeleteClientSponsorInput) {
        clientSponsorDelete(DeleteClientSponsorInput: $deleteClientSponsorInput) {
          _id
          cliente
          sponsor_name
          sponsor_src
          sponsor_image
          sponsor_image_dump
          type
          createAt
          updateAt
        }
      }
      `);

}
