import { ApolloClient, InMemoryCache,ApolloLink  } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

const TokenLogged = localStorage.getItem('tk') === null ? null :  JSON.parse(localStorage.getItem('tk')).token;

const customUploadLink = createUploadLink({
  uri: process.env.REACT_APP_END_POINT_GQL,
  headers: {
    'Authorization': 'Bearer ' + TokenLogged, // sostituisci con il tuo token di autenticazione
    'x-apollo-operation-name': 'uploadFile'  // Aggiungi un'intestazione personalizzata per evitare il blocco CSRF
  }
});

export const Client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([customUploadLink])

});

