import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import DataTablesMenu from "views/admin/tables/menus";
import DataTablesArticles from "views/admin/tables/tablesArticles";
import GalleryImages from "views/admin/gallery";
import Partners from 'views/admin/partners'
import Espositori from 'views/admin/espositori'
import {
  Article,
  QrCode,
  BookOpen,
  User,
  UsersFour ,
  House,
  ImagesSquare,
  Storefront ,
} from "@phosphor-icons/react";
// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <House size={16} className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  { 
    name: "Account",
    layout: "/admin",
    path: "profile",
    icon: <User size={16} className="h-6 w-6" />,
    component: <Profile />,
  },
  { 
    name: "Partners",
    layout: "/admin",
    path: "partners",
    icon: <UsersFour size={16} className="h-6 w-6" />,
    component: <Partners />,
  },
  { 
    name: "Espositori",
    layout: "/admin",
    path: "espositori",
    icon: <Storefront size={16} className="h-6 w-6" />,
    component: <Espositori  />,
  },
  { 
    name: "Menù",
    layout: "/admin",
    path: "cat_men_",
    icon: <BookOpen size={16} className="h-6 w-6" />,
    component: <DataTables />,
  },
  {
    name: "Categoria Menù",
    layout: "/admin",
    path: "menu",
    icon: <BookOpen size={16} className="h-6 w-6" />,
    component: <DataTablesMenu />,
    secondary: true,
  },
  {
    name: "Articoli",
    layout: "/admin",
    icon: <Article size={16} className="h-6 w-6" />,
    path: "articles",
    component: <DataTablesArticles />,
  },
  {
    name: "Galleria",
    layout: "/admin",
    icon: <ImagesSquare size={16} className="h-6 w-6" />,
    path: "gallery",
    disabled: false,
    component: <GalleryImages />,
  },
  {
    name: "QrCode",
    layout: "/admin",
    icon: <QrCode size={16} className="h-6 w-6" />,
    path: "qrcode",
    component: <NFTMarketplace />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    disabled: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
];
export default routes;
