import React, { useState } from "react";
import Card from "components/card";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, message, Popconfirm } from "antd";
const Banner = () => {
  const [form] = Form.useForm();
  const confirm = (e) => {
    console.log(e);
    window.location.href = "mailto: francescoe15@gmail.com";
  };

  return (
    <Card extra={"items-left w-full h-full p-[16px] bg-cover"}>
      <Form form={form} layout="vertical">
        <p className="mb-4">
          Una volta chiesta l'eliminazione di questo account, sarà eliminato
          tutto il contenuto e non potrai più accederne.
        </p>
        <Form.Item>
          <Popconfirm
            title="Sei sicuro di voler eliminare il tuo account?"
            description="Sei sicuro di confermare?"
            onConfirm={confirm}
            okText="Yes"
            okButtonProps={{
              className: "bg-brand-500 hover:!bg-brand-600",
            }}
            cancelText="No"
          >
            <Button className="!bg-red-500 hover:!bg-red-600 text-white" type="danger">
              Richiedi la cancellazione
            </Button>
          </Popconfirm>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Banner;
