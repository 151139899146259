import { gql, useLazyQuery, useMutation } from '@apollo/client';



export function UpdateArticle(){
    
    return useMutation(gql`
        mutation Mutation($updateArticle: UpdateArticle) {
  articleUpdate(UpdateArticle: $updateArticle) {
    _id
    name
    price
    ingredients
    gluten_free
    lactose_free
    vegan_ok
    vegetarian_ok
    allergens {
      title
      description
      image
      createAt
      updateAt
      _id
    }
    photo
    Show
    cat_menu {
      _id
    }
    utente
    note
  }
}`);

}

export function StoreArticle(){
    
  return useMutation(gql`
mutation Mutation($storeArticle: StoreArticle) {
  articleStore(StoreArticle: $storeArticle) {
    _id
    name
    price
    ingredients
    gluten_free
    lactose_free
    vegan_ok
    vegetarian_ok
    allergens {
      title
      description
      image
      createAt
      updateAt
      _id
    }
    photo
    Show
    cat_menu {
      nome
      _id
      ref_menu
    }
    utente
    note
    prodotto_fresco_active
  }
}
`);

}


export function Articles(){
    
    return useLazyQuery(gql`
query ArticlesAuth {
  articlesAuth {
    _id
    name
    price
    ingredients
    gluten_free
    lactose_free
    vegan_ok
    vegetarian_ok
    allergens {
      title
      description
      image
      createAt
      updateAt
      _id
    }
    photo
    Show
    cat_menu {
      _id
      nome
      ref_menu
      utente
      order
      image
      createAt
      updateAt
    }
    utente
    note
  }
  allergens {
    title
    description
    image
    createAt
    updateAt
    _id
  }
  cat_menuAuth {
    _id
    nome
    ref_menu
    utente
    order
    image
    createAt
    updateAt
  }
  menus {
    _id
    nome
    attivo
    cat_menu {
      _id
      nome
    }
  }
}
`);
  
  }