import React, { useState } from "react";
import Card from "components/card";
import { Button, Form, Input, Radio,message} from "antd";
import axios from "axios";
import {ClientUpdatePassword} from 'views/admin/GraphQl/ClientInfo';


const Banner = () => {
    const [updatePassword] = ClientUpdatePassword();
    const [form] = Form.useForm();

    const handleSubmit = (e) => {
        const val = form.getFieldsValue();
        if(val.pass === undefined || val.pass == ""){
            message.error("Compila tutti i campi");
            return;
        }
        if(val.repPass === undefined || val.repPass == ""){
            message.error("Compila tutti i campi");
            return;
        }

        if(val.pass != val.repPass){
            message.error("Le password non coincidono");
            return;
        }

        const key = "init";
        
        updatePassword({
            variables: {
                updateClientInput: {
                    password: val.pass
                }
            }
        }).then(({data}) => {
            //Login avvenuto con successo
            message.open({
                key,
                type: "success",
                content: "Password aggiornata con successo!",
            });
            localStorage.removeItem('tk');
            window.location.href= "/";
        })
        .catch((error) => {
            console.error('Si è verificato un errore:', error);
            message.open({
                key,
                type: "error",
                content: error.message,
            });
        });

        // axios.post(process.env.REACT_APP_END_POINT_API,
        //     {
        //         query: {},
        //         mutation: {
        //             clientisByIdUpdate: {
        //                 id: JSON.parse(localStorage.getItem("tk")).owner._id,
        //                 keys: {
        //                     password: val.repPass,
        //                 },
        //             },
        //         },
        //     },
        //         {
        //         headers: {
        //             "Content-Type": "application/json",
        //             "auth-api-token":"ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
        //             "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
        //         },
        //     }
        // )
        // .then((response) => {
        //     message.open({
        //         key,
        //         type: "success",
        //         content: "Hai cambiato la tua password!",
        //     });
        //     form.resetFields();
        // })
        // .catch((err) => {
        //     if (err.response.status === 400) {
        //         message.open({
        //             key,
        //             type: "error",
        //             content: err.response,
        //         });
        //     }
        // });

    }

    return (
        <>
            <h2 className="text-2xl mb-3 mt-0">• Reimposta password</h2>
            <Card extra={"items-left w-full p-[16px] bg-cover"}>
                <Form form={form} layout="vertical">
                    <Form.Item
                        label="Password d'accesso"
                        required
                        name="pass"
                        tooltip="Questo campo è obbligatorio"
                        defaultValue={form.pass}
                    >
                        <Input type="password" placeholder="Imposta una password" />
                    </Form.Item>
                    <Form.Item
                        label="Ripeti password"
                        required
                        name="repPass"
                        tooltip="Questo campo è obbligatorio"
                        defaultValue={form.repPass}
                    >
                        <Input type="password" placeholder="Ripeti la password impostata" />
                    </Form.Item>
                    <p className="mb-6">
                        
                    </p>
                    <Form.Item>
                        <Button
                            onClick={handleSubmit}
                            className="bg-brand-500 hover:!bg-brand-600"
                        
                            type="primary"
                        >
                            Aggiorna
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
};

export default Banner;
