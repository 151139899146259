import BarChart from "components/charts/BarChart";
import { barChartDataDailyTraffic } from "variables/charts";
import { barChartOptionsDailyTraffic } from "variables/charts";
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import Card from "components/card";

function calcolaVariazionePercentuale(valoreIniziale, valoreFinale) {
  const variazione = ((valoreFinale - valoreIniziale) / valoreIniziale) * 100;
  return variazione.toFixed(2) ;
}

const DailyTraffic = ({date}) => { 
  
  if(Object.values(date).length == 0) return;


  const total = Object.values(date).reduce((accumulator, currentValue) => accumulator + currentValue, 0);


  // Converti l'oggetto in un array di oggetti con chiave e valore
  const dailyStatsArray = Object.entries(date).map(([date, visits]) => ({ date, visits }));

  // Ordina l'array in base alla data
  dailyStatsArray.sort((a, b) => new Date(a.date) - new Date(b.date));

  let reconvertDate = [];

  dailyStatsArray.forEach(element => {
    if (!reconvertDate[element.date]) {
      reconvertDate[element.date] = 0;
    }
    
    reconvertDate[element.date] += element.visits;
  });


  
  const dateKeys = Object.keys(reconvertDate);
  let variazioniGiornaliere = "";

  for (let i = 1; i < dateKeys.length; i++) {
      const giornoPrecedente = dateKeys[i - 1];
      const giornoCorrente = dateKeys[i];
      const variazione = calcolaVariazionePercentuale(reconvertDate[giornoPrecedente], reconvertDate[giornoCorrente]);
      
      variazioniGiornaliere = variazione;
  }
  
  return (
    <Card extra="pb-7 p-[20px]">
      <div className="flex flex-row justify-between">
        <div className="ml-1 pt-2">
          <p className="text-sm font-medium leading-4 text-gray-600">
            Traffico Giornaliero
          </p>
          <p className="text-[34px] font-bold text-navy-700 dark:text-white">
            {total}
            <span className="text-sm font-medium leading-6 text-gray-600">
              Visitors
            </span>
          </p>
        </div>
        <div className="mt-2 flex items-start">
          {
            variazioniGiornaliere > 0 ?
              <div className="flex items-center text-sm text-green-500">
                <MdArrowDropUp className="h-5 w-5" />
                <p className="font-bold"> {variazioniGiornaliere}%</p>
              </div>
            :
              <div className="flex items-center text-sm text-red-500">
                <MdArrowDropDown className="h-5 w-5" />
                <p className="font-bold"> {variazioniGiornaliere}%</p>
              </div>
          }
          
        </div>
      </div>

      <div className="h-[300px] w-full pt-10 pb-0">
        <BarChart
          chartData={[{
            name: "Daily Traffic",
            data: Object.values(reconvertDate),
          }]}
          chartOptions={{
            chart: {
              toolbar: {
                show: false,
              },
            },
            tooltip: {
              style: {
                fontSize: "12px",
                fontFamily: undefined,
                backgroundColor: "#000000",
              },
              onDatasetHover: {
                style: {
                  fontSize: "12px",
                  fontFamily: undefined,
                },
              },
              theme: "dark",
            },
            xaxis: {
              categories: Object.keys(reconvertDate),
              show: false,
              labels: {
                show: true,
                style: {
                  colors: "#A3AED0",
                  fontSize: "14px",
                  fontWeight: "500",
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            yaxis: {
              show: false,
              color: "black",
              labels: {
                show: true,
                style: {
                  colors: "#CBD5E0",
                  fontSize: "14px",
                },
              },
            },
            grid: {
              show: false,
              strokeDashArray: 5,
              yaxis: {
                lines: {
                  show: true,
                },
              },
              xaxis: {
                lines: {
                  show: false,
                },
              },
            },
            fill: {
              type: "gradient",
              gradient: {
                type: "vertical",
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                colorStops: [
                  [
                    {
                      offset: 0,
                      color: "#4318FF",
                      opacity: 1,
                    },
                    {
                      offset: 100,
                      color: "rgba(67, 24, 255, 1)",
                      opacity: 0.28,
                    },
                  ],
                ],
              },
            },
            dataLabels: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: "40px",
              },
            },
          }}
        />
      </div>
    </Card>
  );
};

export default DailyTraffic;
