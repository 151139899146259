import React, { useState, useEffect } from "react";
import Card from "components/card";
import { Tray, Trash,Pencil, Circle } from "@phosphor-icons/react";

import { Button, Form,  message, Upload, List,Avatar,Skeleton, Switch, Tooltip } from "antd";
import axios from "axios";
import {UploadImage} from 'views/admin/GraphQl/Uploader'
import {ClientGallerySet, ClientGalleryDelete} from 'views/admin/GraphQl/ClientInfo'

const { Dragger } = Upload;


const Banner = (props) => {
    const [upload] = UploadImage();
    const [gallerySetImage] = ClientGallerySet();
    const [galleryDel] = ClientGalleryDelete();

    const [form] = Form.useForm();
    const [clientInfo, setClientInfo] = useState({});

    const [load, setLoad] = useState(false);
    const [logoUrl, setLogo] = useState()
    const [coverUrl, setCover] = useState()
    const [galleryList, setGalleryList] = useState();
    const [majorG, setMajorG] = useState();
    const [maxGallery, setMaxGallery] = useState(5)
    const [uploadFileN, setUploadFileN] = useState()

    const handleSubmit = (e) => {

        /*const clientInfos = Object.keys(clientInfo).map((e) => { console.log(e);
            return  (clientInfo[e] === "");
        });

        
        if(clientInfos.includes(true)){
            message.error("Compila tutti i campi");
            return;
        }*/
        
        if(clientInfo.activity_name === "" || 
            clientInfo.activity_type === "" || 
            clientInfo.Logo === "" || 
            clientInfo.header_cover === "" || 
            clientInfo.descriptions === "" || 
            clientInfo.Street === "" || 
            clientInfo.City === "" || 
            clientInfo.parcheggio === ""){
            message.error("Compila tutti i campi");
            return;
        }

        
        if(clientInfo.client_type_filter.length == 0){
            message.error("Devi inserire almeno 1 attività secondaria ");
            return;
        }


        const key = "init";
        setLoad(false);
        axios.post(process.env.REACT_APP_END_POINT_API,
            {
                query: {},
                mutation: {
                    clienti_infosByIdUpdate: {
                        id: JSON.parse(localStorage.getItem("tk")).user._id,
                        keys: clientInfo,
                    },
                },
            },
                {
                headers: {
                    "Content-Type": "application/json",
                    "auth-api-token":"ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
                    "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
                },
            }
        )
        .then((response) => {
            message.open({
                key,
                type: "success",
                content: "Informazioni aggiornate!",
            });
            setLoad(true);
        })
        .catch((err) => {
            if (err.response.status === 400) {
                message.open({
                    key,
                    type: "error",
                    content: err.response,
                });
            }
        });
    };

    const init = () => {
        
        if(clientInfo._id != undefined) return; 
        if (load) return;
        
        const key = "init";

        axios.post(process.env.REACT_APP_END_POINT_API,
            {
                query: {
                    clienti_infosById: {
                        "id": JSON.parse(localStorage.getItem('tk')).user._id,
                        "keys":[
                            "activity_name",
                            "activity_type",
                            "Logo",
                            "header_cover",
                            "coperto",
                            "wifi_space",
                            "descriptions",
                            "Street",
                            "City",
                            "postal_code",
                            "facebook_link",
                            "tiktok_link",
                            "instagram_link",
                            "owner_name",
                            "public_email",
                            "youtube_link",
                            "delivery",
                            "glutenfree",
                            "lactosefree",
                            "delivery_cost",
                            "glutenfree_cost",
                            "lactosefree_cost",
                            "ratings",
                            "parcheggio",
                            "client_type_filter"
                        ]
                    },
                    clienti_galleriasById: {
                        "id": {
                            "cliente": JSON.parse(localStorage.getItem('tk')).user._id
                        },
                        "keys": [
                            "ID",
                            "cliente",
                            "url",
                            "name",
                            "major"
                        ]
                    }
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "auth-api-token":"ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
                    "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
                },
            }
        )
        .then((response) => { console.log(response.data.models.clienti_infos);
            /*setActivity(response.data.models.clienti_infos.activity_name);
            setValue(response.data.models.clienti_infos.activity_type);
            setOwnerName(response.data.models.clienti_infos.owner_name);*/
            setClientInfo(response.data.models.clienti_infos);
            response.data.models.clienti_gallerias.map(item => item.major ? setMajorG(item._id) : false)
            setGalleryList(response.data.models.clienti_gallerias);
            setLogo(response.data.models.clienti_infos.Logo);
            setCover(response.data.models.clienti_infos.header_cover);
            setLoad(true);

        })
        .catch((err) => { console.log(err);
            if (err.response.status === 400) {
                message.open({
                    key,
                    type: "error",
                    content: err.response,
                });
            }
        });
    };

    // init();
    useEffect(() => {
        
        if(!Object.keys(props.data).length === 0){
            props.data.gallerys.map(item => item.major ? setMajorG(item._id) : false)
        }
        setGalleryList(props.data.gallerys);
        setClientInfo(props.data);
        setLogo(props.data.Logo);
        setCover(props.data.header_cover);
        setMaxGallery(props.data.max_gallery == undefined ? 5 : props.data.max_gallery);
    }, [props.data])


    const handleDeleteImage = (e) => {
        
        galleryDel({
            variables: {
                galleryDel: {
                    _id: e._id
                }
            }
        }).then(({data: dataGa}) => {
            
            message.open({
                type: "success",
                content: "Immagine rimossa dalla galleria!",
            });
            setGalleryList(galleryList => galleryList.filter(item => item._id !== e._id));
        }).catch((errors) => {
            message.error('C’è stato un errore durante l’eliminazione dell’immagine, prova a riavviare la pagina.');
            console.error(errors)
        })

    }


    let countG = 0;
    if (galleryList !== undefined){
        countG = galleryList.length
    }

    const key = '00003123';
    
    const dragg = {
        name: 'image',
        multiple: true,
        listType:"text",
        maxCount:maxGallery,
        disabled: countG >= maxGallery ? true : false,
        //defaultFileList: galleryList,
        // action: 'https://server.mynuapp.it/upload/image',
        customRequest: async ({ file, onSuccess, onError }) => {

            if(uploadFileN != undefined){
                if (!uploadFileN.some(existingFile => existingFile.uid === file.uid)) {
                    message.warning(`Puoi selezionare al massimo ${(maxGallery - countG)} file.`);
                    // onError();
                    return;
                }

            }

            await upload({ variables: { file },headers: {'x-apollo-operation-name': 'uploadFile', 'Content-Type': 'multipart/form-data'} }).then(({data}) => {
                
                onSuccess();

                
                gallerySetImage({
                    variables: {
                        galleryPush: {
                            url: process.env.REACT_APP_END_POINT_GALLERY + "/original/" + data.uploadFile,
                            url_dump: process.env.REACT_APP_END_POINT_GALLERY + "/dump/small-" + data.uploadFile,
                            name: data.uploadFile,
                            major: false
                        }
                    }
                }).then(({data: dataGa}) => {
                    message.open({
                        key,
                        type: "success",
                        content: "Galleria aggiornata",
                    });
                    setGalleryList((galleryList) => [...galleryList, dataGa.clientGalleryStore]);
                }).catch((errors) => {
                    message.error('Upload failed.');
                    console.error(errors)
                })
            })
            .catch((error) => {
                message.error('Upload failed.');
                onError(error);
            });

           
        },
        onChange(info) {
            // Verifica il numero di file selezionati
            if (info.fileList.length > (maxGallery - countG)) {
                
                // Rimuovi i file aggiunti oltre il limite
                info.fileList.splice((maxGallery - countG));
                
                // Aggiorna lo stato dei file caricati
                setUploadFileN(info.fileList);
            }

            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
            
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const handleChangeMajor = (bool, me) => {
        
        axios.post(process.env.REACT_APP_END_POINT_API,
            {
                query: {},
                mutation: {
                    clienti_galleriasByIdUpdate: {
                        "id": me.currentTarget.dataset.id,
                        "keys": { 
                            "major": bool
                        }
                    },
                },
            },
                {
                headers: {
                    "Content-Type": "application/json",
                    "auth-api-token":"ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
                    "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
                },
            }
        )
        .then((response) => {
            message.open({
                
                type: "success",
                content: "Aggiunto tra gallery major!",
            });
            if(bool == false){
                
                setMajorG(undefined)
            }else{
                setMajorG(response.data.mutations.clienti_gallerias._id)
            }
        })
        .catch((err) => { console.log(err);
            if (err.response.status === 400) {
                message.open({
                    
                    type: "error",
                    content: err.response,
                });
            }
        });
    }

   
    if(clientInfo['client_type_filter'] === undefined) return;
    
    // clientInfo['client_type_filter'] = [].concat(...clientInfo['client_type_filter']);
    //setClientInfo(clientInfo);
    

    return (
        <>
            <h2 className="text-2xl mb-3 ">• Galleria</h2>
            <Card extra={"items-left w-full p-[16px] bg-cover"}>
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Form.Item
                        label="Galleria Attività"
                        required
                        tooltip="Questo campo è obbligatorio"
                    >
                        <Dragger {...dragg} className="py-10">
                            <p className="ant-upload-drag-icon flex items-center justify-center">
                                <Tray size={40} />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                            banned files.
                            </p>
                        </Dragger>
                    </Form.Item>
                    
                    <h2 className="font-medium text-xl mt-10">Hai caricato N° {countG}/{maxGallery} immagini nella tua galleria</h2>
                    <p className="mb-5">Puoi attivare come immagine Major(Immagine in risalto) soltanto una</p>
                    <List
                        itemLayout="horizontal"
                        dataSource={galleryList}
                        renderItem={(item, index) => (
                        <List.Item actions={[<a onClick={() => handleDeleteImage(item)}><Trash /></a>, <Tooltip placement="left" title={majorG != item._id && majorG !== undefined ? "Ricorda puoi impostare come Major soltanto una immagine" : "Posiziona come Major"} arrow={true}>
                            {/* <Switch                                                                          
                                onChange={handleChangeMajor}
                                className="bg-gray-600 -mt-1"
                                size="small"
                                data-id={item._id}
                                checkedChildren={<Pencil className="relative top-[2px]" />}
                                unCheckedChildren={<Circle className="relative top-[2px]" />}
                                disabled={majorG != item._id && majorG !== undefined }
                                defaultChecked={item.major}
                            /> */}
                        </Tooltip>]}>
                            <Skeleton avatar title={false} loading={item.loading} active>
                                <List.Item.Meta
                                    avatar={<Avatar shape="square" size={64} src={item.url} />}
                                    title={<a href={item.url}>{item.name}</a>}
                                    description={item.desc}
                                />
                            </Skeleton>
                        </List.Item>
                        )}
                    />
                </Form>
            </Card>
        </>
    );
};

export default Banner;
