import { gql,useLazyQuery , useMutation } from '@apollo/client';



export function ClientInfo(){
    
    return useLazyQuery(gql`query Query {
        clientInfoProtected {
          _id
          activity_name
          activity_type
          Logo
          header_cover
          coperto
          wifi_space
          descriptions
          Street
          City
          postal_code
          facebook_link
          tiktok_link
          instagram_link
          owner
          owner_name
          public_email
          youtube_link
          delivery
          user_cart_premit
          glutenfree
          user_booking
          lactosefree
          delivery_cost
          glutenfree_cost
          lactosefree_cost
          veganfree
          vegetarianfree
          vegan_cost
          vegetarian_cost
          ratings
          parcheggio
          client_type
          client_type_filter
          pet
          phone
          user_calendar
          seo_url
          createAt
          updateAt
          active
          sponsor {
            _id
            cliente
            sponsor_name
            sponsor_src
            type
            createAt
            updateAt
          }
          coperto_active
          piscina_active
          area_fumatori_active
          area_giochi_active
          footer_present
          descriptions_full
          title_descriptions_full
          max_gallery
        }
        
      } `);

}


export function ClientInfoFull(){
    
  return useLazyQuery(gql`query Query {
    clientInfoProtected {
      _id
      activity_name
      activity_type
      Logo
      header_cover
      coperto
      wifi_space
      descriptions
      Street
      City
      postal_code
      facebook_link
      tiktok_link
      instagram_link
      owner
      owner_name
      public_email
      youtube_link
      delivery
      user_cart_premit
      glutenfree
      user_booking
      lactosefree
      delivery_cost
      glutenfree_cost
      lactosefree_cost
      veganfree
      vegetarianfree
      vegan_cost
      vegetarian_cost
      ratings
      parcheggio
      client_type
      client_type_filter
      pet
      phone
      user_calendar
      seo_url
      createAt
      updateAt
      clienti_calends {
        _id
        cliente
        giorno
        chiuso
        ora_apert
        ora_chius
        ora_apert_sec_turno
        ora_chius_sec_turno
      }
      gallerys {
        _id
        cliente
        url
        name
        major
      }
      active
      sponsor {
        _id
        cliente
        sponsor_name
        sponsor_src
        sponsor_image
        sponsor_image_dump
        type
        createAt
        updateAt
      }
      coperto_active
      piscina_active
      area_fumatori_active
      area_giochi_active
      footer_present
      descriptions_full
      title_descriptions_full
      max_gallery
    }
  } `);

}

export function ClientInfoUpdate(){
    
  return useMutation(gql`mutation Mutation($storeClientInfoInput: StoreClientInfoInput) {
    clientInfoUpdate(StoreClientInfoInput: $storeClientInfoInput) {
      _id
      activity_name
      activity_type
      Logo
      header_cover
      coperto
      wifi_space
      descriptions
      Street
      City
      postal_code
      facebook_link
      tiktok_link
      instagram_link
      owner_name
      public_email
      youtube_link
      delivery
      user_cart_premit
      glutenfree
      user_booking
      lactosefree
      delivery_cost
      glutenfree_cost
      lactosefree_cost
      veganfree
      vegetarianfree
      vegan_cost
      vegetarian_cost
      ratings
      parcheggio
      client_type
      client_type_filter
      pet
      phone
      user_calendar
      seo_url
      active
      coperto_active
      piscina_active
      area_fumatori_active
      area_giochi_active
      footer_present
      descriptions_full
      title_descriptions_full
    }
  }`);

}

export function ClientGallerySet(){
  return useMutation(gql`mutation ClientGalleryStore($galleryPush: GalleryPush) {
    clientGalleryStore(GalleryPush: $galleryPush) {
      _id
      cliente
      url
      name
      major
    }
  }`);
}

export function ClientGalleryDelete(){
  return useMutation(gql`mutation ClientGalleryDelete($galleryDel: GalleryDel) {
    clientGalleryDelete(GalleryDel: $galleryDel) {
      _id
      cliente
      url
      name
      major
    }
  }`);
}

export function ClientUpdatePassword(){
  return useMutation(gql`mutation Mutation($updateClientInput: UpdateClientInput) {
    clientUpdate(UpdateClientInput: $updateClientInput) {
      email
    }
  }`);
}

export function ClientViews(){

  return useLazyQuery(gql`
    query ClientView($clientId: ID) {
      clientView(client_id: $clientId) {
        client_id
        visits
        device
        country
        createAt
      }
    }
  `);
  
}