import {
  Image,
  Button,
  Form,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Input,
  Tooltip,
  Select,
  Switch
} from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { message } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  PencilSimple,
  Trash,
  UploadSimple,
  X,
  Check,
  CheckSquare,
  XSquare,
} from "@phosphor-icons/react";
// import Modal from "./components/addArticle";
import Modal from "./components/addArticleModal";
// import ModalEdit from "./components/ModalEdit";
import ModalEdit from "./components/ModalEdit2";
import { Link } from "react-router-dom";
import {Articles} from 'views/admin/GraphQl/Articles';


const { TextArea } = Input;
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  catm,
  index,
  children,
  ...restProps
}) => {
  let inputNode;

  

  if (inputType === "number") {
    inputNode = <InputNumber />;
  } else if (inputType === "numberPrice") {
    inputNode = (
      <InputNumber
        formatter={(value) =>
          `${value} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
      />
    );
  } else if (inputType === "textarea" || inputType === "text") {
    inputNode = (
        <TextArea
            
            //onChange={(e) => setValue(e.target.value)}
            placeholder=""
            autoSize={{
                minRows: 3,
                maxRows: 5,
            }}
        />
    );
  } else if (inputType === "boolean") {
    inputNode = (
        <Switch
            className="bg-gray-300"
            checkedChildren={<Check className="mt-[2px]" size={16} />}
            unCheckedChildren={<X className="mt-[7px]" size={16} />}
            defaultChecked={false}
        />
    );
  } else  if (inputType === "object") {
    inputNode = (
      <Select
        showSearch
        required
        style={{
          width: 120,
        }}
        options={catm}
      />
    );
  } else {
    inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Tables = () => {
  const [getArticles] = Articles();

  const key = "updatable";
  const [data, setData] = useState([]);
  const [allergensData, setAllergensData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [catm, setCatm] = useState([]);
  const [menus, setMenus] = useState([]);

  const fetchData = () => {
    setLoading(true);

    getArticles().then(({data, error}) => {
      if(error){
        if(error.graphQLErrors[0].extensions.code === "EXPIRED_TOKEN"){
          localStorage.removeItem('tk');
          window.location.href= "/";
        }
        message.open({
          key,
          type: "error",
          content: error.message,
        });
        return;
      }
      if (data) { 
        setData(data.articlesAuth);
        setAllergensData(data.allergens);
        setCatm(data.cat_menuAuth);
        setMenus(data.menus);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: 200,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      }
    })
    .catch((err) => {
      setLoading(false);
      message.open({
        key,
        type: "error",
        content: "Token d'accesso errato!",
      });
    });

    // axios
    //   .post(
    //     process.env.REACT_APP_END_POINT_API,
    //     {
    //       query: {
    //         articolisById: {
    //             "id": {
    //                 "utente": JSON.parse(localStorage.getItem('tk'))._id,
    //             },
    //             "keys":[
    //                 "ID",
    //                 "name",
    //                 "price",
    //                 "ingredients",
    //                 "gluten_free",
    //                 "lactose_free",
    //                 "allergens",
    //                 "frozen",
    //                 "photo",
    //                 "Show",
    //                 "cat_menu",
    //                 "utente",
    //                 "note"
    //             ]
    //         },
    //         "allergenssMany": [
    //           "ID",
    //           "title",
    //           "description",
    //           "image"
    //         ]
    //       },
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         "auth-api-token":
    //             "ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
    //           "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     if (response.data) {
    //       setData(response.data.models.articolis);
    //       setAllergensData(response.data.models.allergens);
    //       setLoading(false);
    //       setTableParams({
    //         ...tableParams,
    //         pagination: {
    //           ...tableParams.pagination,
    //           total: 200,
    //           // 200 is mock data, you should read it from server
    //           // total: data.totalCount,
    //         },
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     if (err.response.status === 400) {
    //       message.open({
    //         key,
    //         type: "error",
    //         content: "Token d'accesso errato!",
    //       });
    //     }
    //   });

    const key_load_cat_menu = "load_cat_menu";
    let lm = [];
    // if (catm.length == 0) {
    //   axios
    //     .post(
    //       process.env.REACT_APP_END_POINT_API,
    //       {
    //         query: {
    //           cat_menusById: {
    //             id: {
    //               utente: JSON.parse(localStorage.getItem('tk'))._id
    //             },
    //             keys: ["nome","ref_menu","utente"]
    //           },
    //         },
    //       },
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //           "auth-api-token":
    //             "ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
    //           "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       response.data.models.cat_menus.forEach((element, i) => {
    //         lm[i] = {
    //           value: element._id,
    //           label: <span>{element.nome} - Menù <b>{element.ref_menu.nome}</b></span>,
    //         };
    //       });
    //       setCatm(lm);
    //     })
    //     .catch((err) => {
    //       if (err.response.status === 400) {
    //         message.open({
    //           key_load_cat_menu,
    //           type: "error",
    //           content: "Token d'accesso errato!",
    //         });
    //       }
    //     });
    // }
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record._id === editingKey;

  const handleDelete = (key) => {
    const newData = data.filter((item) => item._id !== key._id);

    const key_modal = "updatable";

    axios
      .post(
        process.env.REACT_APP_END_POINT_API,
        {
          query: {},
          mutation: {
            articolisByIdRemove: {
              id: key._id,
            },
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-api-token":
                "ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
              "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
          },
        }
      )
      .then((response) => {
        //console.log(response);
        if (response.data) {
          if (response.data.error) {
            message.open({
              key_modal,
              type: "error",
              content: response.data.error,
            });
          } else {
            message.open({
              key_modal,
              type: "success",
              content: "Menù eliminato",
            });
            setData(newData);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.open({
            key_modal,
            type: "error",
            content: "Token d'accesso errato!",
          });
        }
      });
  };

  const columns = [
    {
      title: "",
      dataIndex: "operation",
      inputType: "text",
      width: "100px",
      fixed: 'left',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="flex">
            <Typography.Link
              onClick={() => save(record._id)}
              style={{
                marginRight: 8,
              }}
            >
              <UploadSimple size={20} />
            </Typography.Link>
            <Popconfirm title="Vuoi annullare?" onConfirm={cancel}>
              <X size={20} />
            </Popconfirm>
          </span>
        ) : (
          <span className="flex">
            <Typography.Link
              className="mr-2"
              disabled={editingKey !== ""}
              //onClick={() => edit(record)}
              onClick={() => {
               
                setModalEditShow(!modalEditShow);
                setDataEdit(record);
              }}
            >
              <PencilSimple size={20} />
            </Typography.Link>
            <Popconfirm
              title="Vuoi eliminare?"
              onConfirm={() => handleDelete(record)}
            >
              <Trash color="red" size={20} />
            </Popconfirm>
          </span>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 200,
      inputType: "text",
      editable: true,
    },
    {
      title: "Note",
      dataIndex: "note",
      width: 200,
      inputType: "text",
      editable: true,
    },
    {
      title: "Prezzo",
      dataIndex: "price",
      width: 150,
      inputType: "numberPrice",
      editable: true,
      render: (_, record) => {
        return _ + " €";
      },
    },
    {
      title: "Ingredienti",
      dataIndex: "ingredients",
      editable: true,
      width: 200,
      inputType: "textarea",
      render: (_, record) => {
        return _ == "null" ? "-" :  _ != null ? _.join(",") : _;
      },
    },
    {
      title: "No Glutine",
      dataIndex: "gluten_free",
      editable: true,
      width: 100,
      inputType: "boolean",
      render: (_, record) => {
        return _ === true ? (
          <Tooltip placement="left" title={"Non Contiene Glutine"}>
            <CheckSquare size={20} />{" "}
          </Tooltip>
        ) : (
          <Tooltip placement="left" title={"Contiene Glutine"}>
            <XSquare size={20} />
          </Tooltip>
        );
      },
    },
    {
      title: "No Lattosio",
      dataIndex: "lactose_free",
      editable: true,
      width: 100,
      inputType: "boolean",
      render: (_, record) => {
        return  _ === true ? (
          <Tooltip placement="left" title={"Non Contiene Lattosio"}>
            <CheckSquare size={20} />{" "}
          </Tooltip>
        ) : (
          <Tooltip placement="left" title={"Contiene Lattosio"}>
            <XSquare size={20} />
          </Tooltip>
        );
      },
    },
    {
      title: "Allergeni",
      dataIndex: "allergens",
      width: 200,
      editable: true,
      inputType: "textarea",
      render: (_, record) => { 
        const filteredData = _.map(item => { 
          return item.title; // Se l'allergene non è presente nei dati
        })
        
        return _ == "null" ? "-" : filteredData.join(",");
      },
    },
    {
      title: "Mostra",
      dataIndex: "Show",
      editable: true,
      width: 100,
      inputType: "boolean",
      render: (_, record) => {
        return _ === true ? (
          <Tooltip placement="left" title={"Visibile"}>
            <CheckSquare size={20} />{" "}
          </Tooltip>
        ) : (
          <Tooltip placement="left" title={"Non visibile"}>
            <XSquare size={20} />
          </Tooltip>
        );
      },
    },
    {
      title: "Congelato",
      dataIndex: "frozen",
      editable: true,
      width: 100,
      inputType: "boolean",
      render: (_, record) => {
        return _ === true ? (
          <Tooltip placement="left" title={"Visibile"}>
            <CheckSquare size={20} />{" "}
          </Tooltip>
        ) : (
          <Tooltip placement="left" title={"Non visibile"}>
            <XSquare size={20} />
          </Tooltip>
        );
      },
    },
    {
      title: "Foto",
      dataIndex: "photo",
      editable: true,
      width: 100,
      inputType: "text",
      render: (_, record) => {
        return _ == "null" ? "-" : <Image width={35} src={_} />;
      },
    },
    {
      title: "Menù",
      dataIndex: "cat_menu",
      editable: true,
      width: 100,
      inputType: "object",
      render: (_, record) => {
        if (_) {
          return (
            <Link
              to={"/admin/menu#"+ _._id}
              className="text-blue-600"
            >
              {_.nome}
            </Link>
          );
        }
      },
    },
  ];

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      ...record,
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();

      //console.log(row.cat_menu);
      if (row.cat_menu._id) {
        message.open({
          type: "error",
          content: "Devi compilare tutti i campi!",
        });
        return;
      }
      const newData = [...data];
      const index = newData.findIndex((item) => key === item._id);

      if (index > -1) {
        const item = newData[index];
        if (!row.cat_menu._id) {
          const cat_menu_split = row.cat_menu.split("|");
          row.cat_menu = {
            _id: cat_menu_split[0],
            name: cat_menu_split[1],
          };
        }

        newData.splice(index, 1, {
          ...item,
          ...row,
        });

        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }

      const key_popup = "updatable";
      //console.log(row);
      axios
        .post(
          process.env.REACT_APP_END_POINT_API,
          {
            query: {},
            mutation: {
            articolisByIdUpdate: {
                id: key,
                keys: {
                  name: row.name,
                  price: row.price,
                  ingredients: row.ingredients,
                  gluten_free: row.gluten_free,
                  lactose_free: row.lactose_free,
                  allergens: row.allergens,
                  photo: row.photo,
                  Show: row.Show,
                  cat_menu: row.cat_menu._id,
                },
              },
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-api-token":
                "ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
              "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
            },
          }
        )
        .then((response) => {
          if (response.data) {
            if (response.data.error) {
              message.open({
                key_popup,
                type: "error",
                content: response.data.error,
              });
            } else {
              message.open({
                key_popup,
                type: "success",
                content: "Menù aggiornato!",
              });
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            message.open({
              key_popup,
              type: "error",
              content: "Token d'accesso errato!",
            });
          }
        });
    } catch (errInfo) {
      //console.log("Validate Failed:", errInfo);
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        catm: catm,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const [modalShow, setModalShow] = useState(false);
  const [modalEditShow, setModalEditShow] = useState(false);
  const [dataEdit, setDataEdit] = useState();
  

  const modalok = (name) => {
    //setModalShow(!modalShow);
    /*const newData = {
      key: data.length,
      name: name,
    };
    setData([...data, newData]);*/
    fetchData();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const updateData = (d) => {

    /*const newData = [...data];
    const index = newData.findIndex((item) => d._id === item._id);
    if (index > -1) {
      const item = newData[index];
      console.log(item);

      
      /*newData.splice(index, 1, {
        ...item,
        ...d,
      });
      Object.assign(item, d);
      
      setData(newData);
      setEditingKey("");
    }*/

    setTimeout(() => {
      // window.location.reload();
    }, 1000);

  }

  const modalShow_ = () => {
    setModalShow(!modalShow);
  };
  const modalEditShow_ = () => {
    setModalEditShow(!modalEditShow);
  };

  
  return (
    <div className="mt-5 grid h-full grid-cols-1 gap-5">
      <div className="grid grid-cols-1 grid-rows-1 gap-5">
        <div>
          <Button
            onClick={modalShow_}
            className="bg-indigo-700 hover:!bg-indigo-800"
            type="primary"
          >
            Aggiungi Articolo
          </Button>
        </div>

        <div>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              columns={mergedColumns}
              rowKey={(record) => record._id}
              dataSource={data}
              /*pagination={tableParams.pagination}*/
              loading={loading}
              onChange={handleTableChange}
              rowClassName="editable-row"
              pagination={{
                onChange: cancel,
              }}
              scroll={{
                y: 500,
              }}
            />
          </Form>
        </div>
      </div>
      <Modal show={modalShow} modalShow={modalShow_} ok={modalok} catm={catm} menus={menus} allergens={allergensData} />
      {dataEdit ?
      <ModalEdit show={modalEditShow} modalShow={modalEditShow_} ok={updateData} data={dataEdit} catm={catm} menus={menus} allergens={allergensData} /> : ""
      }
    </div>
  );
};

export default Tables;
