import { Form, Input, Modal, InputNumber, message, Switch, Select,Upload, Checkbox, Button } from "antd";
import { useState, useEffect } from "react";
import { X, Check } from "@phosphor-icons/react";
import axios from "axios";
import Tags from "components/tags";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {StoreArticle} from '../../GraphQl/Articles';

const { TextArea } = Input;


const App = (props) => {
  const [storeArticle] =  StoreArticle();


  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [price, setPrice] = useState(0);
  const [ingredients, setIngredients] = useState([]);
  const [gluten, setGluten] = useState(false);
  const [lactose, setLactose] = useState(false);
  const [vegan_ok, setVeganOk] = useState(false);
  const [vegetarian_ok, setVegetarianOk] = useState(false);
  const [frozen, setFrozen] = useState(false);
  const [allergens, setAllergens] = useState([]);
  const [show, setShow] = useState(true);
  const [category, setCategory] = useState("");
  const [menuss, setMenuss] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [prodottoFresco, setProdottoFresco] = useState(false);

  const [loadingLogo, setLoadingLogo] = useState(false);
  const [logoUrl, setLogo] = useState()
  const [catm, setCatm]  =useState(props.catm)
  const [menus, setMenus] = useState(props.menus);

  // useEffect(() => {
  //   const catm_ = props.catm.map(item => {  
  //     return { value: item._id,
  //             label: item.nome
  //           }
  //   });
  //   setCatm(catm_);
  // }, [props.catm])

  useEffect(() => {
    const filter = props.menus.filter(e => e._id === menuss)
    
    if(filter.length === 0) return

    const catm_ = filter[0].cat_menu.map(item => {  
      return { value: item._id,
              label: item.nome
            }
    });

    setCatm(catm_);
  }, [menuss])

  useEffect(() => {
    
    const menus_ = props.menus.map(item => {  
      return { value: item._id,
              label: item.nome
            }
    });
    setMenus(menus_);
    setMenuss("");
  }, [props.menus])

  const handleOk = () => {
    const key = "add";

    if (
      name.length <= 0 ||
      category.length <= 0 
    ) {
      message.open({
        key,
        type: "error",
        content: "Completa tutti i campi!",
      });
      return;
    }
    

    storeArticle({
      variables: {
        storeArticle: {
          name: name,
          price: price,
          ingredients: ingredients,
          gluten_free: gluten,
          lactose_free: lactose,
          vegan_ok: vegan_ok,
          vegetarian_ok: vegetarian_ok,
          allergens: allergens,
          photo: photoUrl,
          Show: show,
          frozen: frozen,
          cat_menu: category.split("|")[0],
          note: note,
          prodotto_fresco_active: prodottoFresco
        }
      }
    }).then(data => {
      if (data) {
        if (data.error) {
          message.open({
            key,
            type: "error",
            content: data.error,
          });
        } else {
          message.open({
            key,
            type: "success",
            content: "Articolo aggiunto!",
          });
          props.ok({
            name: name,
            price: price,
            ingredients: ingredients.join(","),
            gluten_free: gluten,
            lactose_free: lactose,
            vegan_ok: vegan_ok,
            vegetarian_ok: vegetarian_ok,
            allergens: allergens.join(","),
            photo: photoUrl,
            Show: show,
            cat_menu: category.split("|")[0],
            frozen: frozen,
            note: note
          });
          props.modalShow();
          form.resetFields();
        }
      }

      
    }).catch(err => {
      if (err.response.status === 400) {
        message.open({
          key,
          type: "error",
          content: "Token d'accesso errato!",
        });
      }
    });


  };
  const handleCancel = () => {
    props.modalShow();
  };

  const handleName = (e) => {
    const val = e.target.value;
    setName(val);
  };

  const handleNote = (e) => { 
    const val = e.target.value;
    setNote(val);
  }

  const handleVisibility = (e) => {
    setShow(e);
  };
  const handleTags = (e) => {
    
    // Verifica se il valore è già presente nell'array checkedValues
    if (allergens.includes(e)) {
      // Se è presente, rimuovilo dall'array
      setAllergens(allergens.filter((value) => value !== e));
    } else {
      // Se non è presente, aggiungilo all'array
      setAllergens([...allergens, e]);
    }
    
  };
  const handleIngredients = (e) => {
    setIngredients(e);
  };
  const handleFrozen = (e) =>{
    setFrozen(e);
  }
  const handleGlutenFree = (e) => {
    setGluten(e);
  };

  const handleVeganFree = (e) => {
    setVeganOk(e);
  };
  const handleVegetarianFree = (e) => {
    setVegetarianOk(e);
  };

  const handleLactoseFree = (e) => {
    setLactose(e);
  };
  const handlePriceChange = (e) => {
    setPrice(e);
  };
  const handlePhotoUrl = (e) => {
    const val = e.target.value;
    setPhotoUrl(val);
  };
  const menusSelect = (e) => { 
    setMenuss(e);
  };
  const handleSelect = (e) => {
    setCategory(e);
  };
  const handleProdottoFresco = (e) => {
    setProdottoFresco(e);
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const handleChange = (info) => {
    if (info.file.status === 'uploading') {
        setLoadingLogo(true);
        return;
    }
    if (info.file.status === 'done') {
        // Get this url from response in real world.
        setPhotoUrl(info.file.response.imageUrl);

        getBase64(info.file.originFileObj, (url) => {
            setLoadingLogo(false);
            setLogo(url);
        });
    }
};
const removeImage = () => {
  setPhotoUrl("");
  setLogo("");
}

const uploadButton = (
    <div>
        {loadingLogo  ? <LoadingOutlined /> : <PlusOutlined />}
        
        <div
            style={{
            marginTop: 8,
            }}
        >
            Upload
        </div>
    </div>
);

  return (
    <Modal
      okButtonProps={{
        className: "bg-indigo-700 hover:!bg-indigo-800",
      }}
      title="Aggiungi un articolo per il tuo menù"
      open={props.show}
      className="!rounded-lg md:!px-24 !px-5 !w-full !top-16 "
      classNames={{
        mask: "!bg-gray-600",
        header: "!bg-lightPrimary ml-4",
        content: "!bg-lightPrimary"
      }}
      
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        /*onValuesChange={onRequiredTypeChange}*/
        className="mt-4 p-3"
      >
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-7">
            <div className="bg-white rounded-lg shadow">
              <div className="py-3 px-6 border-b border-gray-200">
                <div className="text-md font-medium">Informazioni Prodotto</div>
              </div>
              <div className="p-6">
                <Form.Item
                  label="Nome Prodotto"
                  name="name"
                  required
                  tooltip="Inserisci un nome a questo prodotto"
                >
                  <Input onChange={handleName} placeholder="Es. Frittata e zucchine" />
                </Form.Item>
                <Form.Item
                  label="Foto Prodotto"
                  name="pohto"
                  tooltip="Inserisci il link della foto di presentazione del tuo prodotto"
                >
                  <div className="relative inline-flex flex-center">
                    <Upload
                        name="image"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://server.mynuapp.it/upload/image"
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                    >
                        {logoUrl ? (
                        <img
                            src={logoUrl}
                            alt="avatar"
                            style={{
                            width: '100%',
                            }}
                        />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                    <Button type="text" className="" onClick={removeImage} disabled={!logoUrl} icon={<X className="top-[2px] relative" />}>Rimuovi immagine</Button>
                  </div>
                  {/*<Input placeholder="url: http://..." onChange={handlePhotoUrl} />*/}
                </Form.Item>
                <Form.Item
                  label="Note Prodotto"
                  name="note"
                  tooltip="Inserisci delle note a questo prodotto"
                >
                  <TextArea
                    onChange={handleNote}
                    autoSize={{
                      minRows: 2,
                      maxRows: 6,
                    }}
                  />
                </Form.Item>
                
                <Form.Item
                  label="Ingredienti"
                  name="ingredients"
                  tooltip="Inserisci in modo dettagliato tutti gli ingredienti che contiene questo prodotto"
                >
                  <Tags tags={handleIngredients} />
                </Form.Item>
                
                <Form.Item
                  label="Allergeni"
                  name="allergens"
                  tooltip="Inserisci gli allergeni che può contenere il tuo prodotto"
                >
                  {/*<Tags tags={handleTags} />*/}
                  {props.allergens.map((item) =>{
                    return <Checkbox key={item._id} name="tags[]" value={item._id} onChange={() => handleTags(item._id)}>{item.title}</Checkbox>;
                  })}
                </Form.Item>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow mt-4">
              <div className="py-3 px-6 border-b border-gray-200">
                <div className="text-md font-medium">Prezzo Prodotto</div>
              </div>
              <div className="p-6">
                <Form.Item
                  label="Prezzo in €"
                  name="price"
                  required
                  tooltip="Inserisci il prezzo di questo prodotto"
                >
                  <InputNumber
                    defaultValue="1"
                    step={0.01}
                    className="w-full"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={handlePriceChange}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-5">
            <div className="bg-white rounded-lg shadow mb-4">
              <div className="py-3 px-6 border-b border-gray-200">
                <div className="text-md font-medium">Categoria Prodotto</div>
              </div>
              <div className="p-6">
              <Form.Item
                  label="Menù"
                  name="menu"
                  
                  required
                  tooltip="Inserisci la il menù d'appartenenza"
                >
                  <Select
                    onChange={menusSelect}
                    showSearch
                    required
                    options={menus}
                  />
                  
                </Form.Item>
                <Form.Item
                  label="Categoria menù"
                  name="cat_menu"
                  
                  required
                  tooltip="Inserisci la categoria d'appartenza del menù"
                >
                  <Select
                    onChange={handleSelect}
                    showSearch
                    required
                    disabled={!menuss}
                    options={catm}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow ">
              <div className="py-3 px-6 border-b border-gray-200">
                <div className="text-md font-medium">Toggle Prodotto</div>
              </div>
              <div className="p-6">
                <Form.Item
                  label="Senza glutine"
                  name="glutenfree"
                  tooltip="Questo prodotto è senza glutine?"
                >
                  <Switch
                    onChange={handleGlutenFree}
                    className="bg-gray-300"
                    checkedChildren={<Check className="mt-[2px]" size={16} />}
                    unCheckedChildren={<X className="mt-[1px]" size={16} />}
                  />
                </Form.Item>
                <Form.Item
                  label="Senza lattosio"
                  name="lactosefree"
                  tooltip="Questo prodotto è senza lattosio?"
                >
                  <Switch
                    onChange={handleLactoseFree}
                    className="bg-gray-300"
                    checkedChildren={<Check className="mt-[2px]" size={16} />}
                    unCheckedChildren={<X className="mt-[1px]" size={16} />}
                  />
                </Form.Item>
                
                <Form.Item
                  label="Prodotto Vegano"
                  name="vegan_ok"
                  tooltip="Questo prodotto è vegano?"
                >
                  <Switch
                    onChange={handleVeganFree}
                    defaultChecked={vegan_ok}
                    className="bg-gray-300"
                    checkedChildren={<Check className="mt-[2px]" size={16} />}
                    unCheckedChildren={<X className="mt-[1px]" size={16} />}
                  />
                </Form.Item>

                <Form.Item
                  label="Prodotto Vegetariano"
                  name="vegetarian_ok"
                  tooltip="Questo prodotto è vegetariano?"
                >
                  <Switch
                    onChange={handleVegetarianFree}
                    defaultChecked={vegetarian_ok}
                    className="bg-gray-300"
                    checkedChildren={<Check className="mt-[2px]" size={16} />}
                    unCheckedChildren={<X className="mt-[1px]" size={16} />}
                  />
                </Form.Item>

                <Form.Item
                  label="Prodotto congelato"
                  name="frozen"
                  tooltip="Questo prodotto è stato congelato?"
                >
                  <Switch
                    onChange={handleFrozen}
                    className="bg-gray-300"
                    checkedChildren={<Check className="mt-[2px]" size={16} />}
                    unCheckedChildren={<X className="mt-[1px]" size={16} />}
                  />
                </Form.Item>
                <Form.Item
                  label="Prodotto fresco"
                  name="prodotto_fresco_active"
                  tooltip="Questo è un prodotto fresco?"
                >
                  <Switch
                    onChange={handleProdottoFresco}
                    className="bg-gray-300"
                    checkedChildren={<Check className="mt-[2px]" size={16} />}
                    unCheckedChildren={<X className="mt-[1px]" size={16} />}
                  />
                </Form.Item>
                <Form.Item
                  label="Rendi visibile articolo nel menù"
                  name="show"
                  required
                  tooltip="Scegli se mostrare questo articolo"
                >
                  <Switch
                    onChange={handleVisibility}
                    className="bg-gray-300"
                    checkedChildren={<Check className="mt-[2px]" size={16} />}
                    unCheckedChildren={<X className="mt-[1px]" size={16} />}
                    defaultChecked
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
export default App;
