import { Form, Input, Modal, message, Switch } from "antd";
import { useState } from "react";
import { X, Check } from "@phosphor-icons/react";
import axios from "axios";
import Tags from "components/tags";
import Uploader from "components/uploader";

const App = (props) => {
  const [form] = Form.useForm();
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [visible, setVisible] = useState(false);
  const [image, setImage] = useState("");
  const { TextArea } = Input;
  const handleOk = () => {
    if (description.length <= 0) {
      message.open({
        type: "error",
        content: "Devi inserire una descrizione",
      });
      return;
    }
    if (image.length <= 0) {
      message.open({
        type: "error",
        content: "Devi inserire una immagine",
      });
      return;
    }

    props.ok({
      description: description,
      tags: tags,
      visible: visible,
      image: image,
    });
    const key = "updatable";
  };
  const handleCancel = () => {
    props.modalShow();
  };

  const handleDescChange = (e) => {
    const value = e.currentTarget.value;
    setDescription(value);
  };
  const handleVisibility = (e) => {
    setVisible(e);
  };

  const handleTags = (e) => {
    setTags(e);
  };
  const handleImage = (e) => {
    setImage(e.file);
  };

  console.log(description, visible, image, tags);

  return (
    <Modal
      okButtonProps={{
        className: "bg-indigo-700 hover:!bg-indigo-800",
      }}
      title="Carica immagine"
      open={props.show}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical" className="mt-4">
        <Form.Item
          className="mb-4"
          label="Descrizione"
          name="description"
          required
          tooltip="Aggiungi una descrizione a questa immagine"
        >
          <TextArea onChange={handleDescChange} rows={3} />
        </Form.Item>
        <Form.Item
          className="mb-4"
          label="Dai dei tag a questa img"
          name="tags"
          required
          tooltip="Aggiungendo più tag su questa immagine avrai più possibilità di ricerca sui browser web"
        >
          <Tags tags={handleTags} />
        </Form.Item>
        <Form.Item
          className="mb-4"
          label="Rendere visibile"
          name="visible"
          required
          tooltip="Puoi scegliere se rendere visibile questa immagine"
        >
          <Switch
            onChange={handleVisibility}
            checkedChildren={<Check size={16} />}
            unCheckedChildren={<X size={16} />}
            defaultChecked
          />
        </Form.Item>
        <Form.Item
          className="mb-4"
          label="Carica immagine"
          name="upload"
          required
          tooltip="Puoi scegliere se rendere visibile questa immagine"
        >
          <Uploader image={handleImage} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default App;
