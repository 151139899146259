import React from 'react';
import { InboxOutlined } from '@ant-design/icons';

import { message, Upload } from 'antd';

function App(props){

    const { Dragger } = Upload;
    
    return <Dragger 
                name='file'
                accept="image/*"
                multiple={false}
                listType="text"
                maxCount="1"
                disabled={props.disabled}
                //defaultFileList="galleryList"
                //action='http://localhost:5002/graphql'
                customRequest = {async ({ file, onSuccess, onError }) => {

                    onSuccess();
                    props.uploadFile(file, onSuccess, onError);
                   
                }}
                // onChange = {(info) =>{
                //     if (info.file.status === 'done') {
                //         message.success(`${info.file.name} file uploaded successfully`);
                //     } else if (info.file.status === 'error') {
                //         message.error(`${info.file.name} file upload failed.`);
                //     }
                // }}
                onDrop = {(e) =>{
                    console.log('Dropped files', e.dataTransfer.files);
                }}
        >
        <p className="ant-upload-drag-icon">
            <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
            banned files.
        </p>
    </Dragger>
};
export default App;