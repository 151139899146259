import React from "react";
import {  message } from "antd";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import { columnsDataComplex } from "views/admin/default/variables/columnsData";
import {ClientInfoFull} from 'views/admin/GraphQl/ClientInfo'

export default function Admin(props) {
  const [getClientInfoFull] = ClientInfoFull();

  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  const [data, setData] = React.useState({});
  const key = new Date();

  if(localStorage.getItem('tk') === null){
    window.location.href = "/"
  }

  if(JSON.parse(localStorage.getItem('tk')).token === undefined){
    localStorage.removeItem('tk');
  }


  React.useEffect(() => {

    getClientInfoFull().then(({data, error, errors}) => { 
      if(error || errors){ 
        if(errors[0].extensions.code === "EXPIRED_TOKEN"){
          localStorage.removeItem('tk');
          window.location.href= "/";
        }
        if(error.graphQLErrors[0].extensions.code === "EXPIRED_TOKEN"){
          localStorage.removeItem('tk');
          window.location.href= "/";
        }
        message.open({
          key,
          type: "error",
          content: error.message,
        });
        return;
      }

      // if(data.clientInfoProtected.client_type_filter.includes('event') === false){
      //   window.location.href = "/";
      // }

      setData(data.clientInfoProtected);
    }).catch((error) => {
      console.error('Si è verificato un errore:', error);
      message.open({
        key,
        type: "error",
        content: error.message,
      });
    });
  }, [data])


  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      

      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
       
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (!localStorage.getItem("tk")) {
          window.location.href = "/";
        }
      
        return (
          <Route path={`/${prop.path}`} element={React.cloneElement(prop.component, { data })} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  
  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} data={data} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"Horizon UI Tailwind React"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              data={data}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
