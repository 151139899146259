import React, { useState, useEffect } from "react";
import Card from "components/card";
import { Button, Form, Switch, TimePicker ,message,Select, List, Skeleton } from "antd";
import axios from "axios";
import {Trash} from '@phosphor-icons/react';
import { columnsDataComplex } from "views/admin/default/variables/columnsData";

const Calendar = (props) => {
    const [form] = Form.useForm();
    const [loadData, setLoadData ] = useState(false);
    const [day, setDay] = useState("");
    const [close, setClose] = useState(false);
    const [list, setList] = useState([]);
    const key = "init";

    const load = () => {

        if(loadData) return;

        axios.post(process.env.REACT_APP_END_POINT_API,
            {
                query: {
                    clienti_calendsById:{
                        id: {
                            cliente: JSON.parse(localStorage.getItem('tk')).users._id
                        },
                        keys: [
                            "ID",
                            "giorno",
                            "ora_apert",
                            "ora_chius",
                            "ora_apert_sec_turno",
                            "ora_chius_sec_turno",
                            "desc",
                            "chiuso",
                            "data_evento"
                        ]
                    }
                },
            },
                {
                headers: {
                    "Content-Type": "application/json",
                    "auth-api-token":"ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
                    "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
                },
            }
        )
        .then((response) => {
            setLoadData(true);
            setList(response.data.models.clienti_calends);
        })
        .catch((err) => {
            if (err.response.status === 400) {
                message.open({
                    key,
                    type: "error",
                    content: err.response,
                });
            }
        });
    }


    useEffect(() => {
        setList(props.data.clienti_calends);
    }, [props.data])

    if(list == undefined){
        //load();
        return;
    }

    const handleSubmit = (e) => {
        const val = form.getFieldsValue();
        
        if(val.giorno === undefined) {
            message.error("Devi prima inserire un giorno");
            return;
        }
        if(val.chiuso === undefined){
            //val.chiuso = false;
            form.setFieldsValue({
                chiuso : false
            });
        }

        
        if(val.chiuso == false && val.ora_apert === undefined && val.ora_chius === undefined || val.chiuso == false && val.ora_apert_sec_turno === undefined && val.ora_chius_sec_turno) {
            message.error("Inserisci un orario per l'apertura del primo turno o secondo turno.");
            return;
        }

        const checklist = list.filter(item => item.giorno == val.giorno);
        
        if(checklist.length > 0){
            message.error("Giorno " + val.giorno + " già essistente");
            return;
        }
        
        axios.post(process.env.REACT_APP_END_POINT_API,
            {
                query: {},
                mutation: {
                    clienti_calendsCreate: {
                        keys: {
                            "cliente" : JSON.parse(localStorage.getItem('tk')).user._id,
                            "giorno": val.giorno,
                            "ora_apert": val.ora_apert,
                            "ora_chius": val.ora_chius,
                            "ora_apert_sec_turno": val.ora_apert_sec_turno,
                            "ora_chius_sec_turno": val.ora_chius_sec_turno,
                            "chiuso": val.chiuso,
                        },
                    },
                },
            },
                {
                headers: {
                    "Content-Type": "application/json",
                    "auth-api-token":"ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
                    "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
                },
            }
        )
        .then((response) => {
            message.open({
                key,
                type: "success",
                content: "Giorno aggiunto!",
            });
            form.resetFields();

            setList(oldArray => [...oldArray, {
                giorno: val.giorno,
                ora_apert: val.ora_apert,
                ora_chius: val.ora_chius,
                ora_sec_apert: val.ora_apert_sec_turno,
                ora_sec_chius: val.ora_chius_sec_turno,
                chiuso: val.chiuso
            }]);
            window.location.reload();
        })
        .catch((err) => {
            if (err.response.status === 400) {
                message.open({
                    key,
                    type: "error",
                    content: err.response,
                });
            }
        });
        
        // axios.post(process.env.REACT_APP_END_POINT_API,
        //     {
        //         query: {},
        //         mutation: {
        //             clienti_calendsCreate: {
        //                 keys: {
        //                     "cliente" : JSON.parse(localStorage.getItem('tk')).users._id,
        //                     "giorno": val.giorno,
        //                     "ora_apert": val.ora_apert,
        //                     "ora_chius": val.ora_chius,
        //                     "ora_apert_sec_turno": val.ora_apert_sec_turno,
        //                     "ora_chius_sec_turno": val.ora_chius_sec_turno,
        //                     "chiuso": val.chiuso,
        //                 },
        //             },
        //         },
        //     },
        //         {
        //         headers: {
        //             "Content-Type": "application/json",
        //             "auth-api-token":"ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
        //             "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
        //         },
        //     }
        // )
        // .then((response) => {
        //     message.open({
        //         key,
        //         type: "success",
        //         content: "Giorno aggiunto!",
        //     });
        //     form.resetFields();

        //     setList(oldArray => [...oldArray, {
        //         giorno: val.giorno,
        //         ora_apert: val.ora_apert,
        //         ora_chius: val.ora_chius,
        //         ora_sec_apert: val.ora_apert_sec_turno,
        //         ora_sec_chius: val.ora_chius_sec_turno,
        //         chiuso: val.chiuso
        //     }]);
        //     window.location.reload();
        // })
        // .catch((err) => {
        //     if (err.response.status === 400) {
        //         message.open({
        //             key,
        //             type: "error",
        //             content: err.response,
        //         });
        //     }
        // });

    }

    const handleChange = (e) => {

        setDay(e);
    }

    const getTime = (e) => {
        var date = new Date(parseInt(e));

        return date.getHours() + ":" + date.getMinutes();
    }

    const removeItem = (idToRemove) => {
        const updatedList = list.filter(item => item._id !== idToRemove);

        // Aggiorna lo stato con la nuova lista
        setList(updatedList);
    };

    const handleRemove = (e) => { 

    
        axios.post(process.env.REACT_APP_END_POINT_API,
            {
                query: {},
                mutation: {
                    clienti_calendsByIdRemove:{
                        id: e,
                        cliente: JSON.parse(localStorage.getItem('tk')).users._id,
                    }  
                }
            },
                {
                headers: {
                    "Content-Type": "application/json",
                    "auth-api-token":"ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
                    "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
                },
            }
        )
        .then((response) => {
            removeItem(e);
        })
        .catch((err) => {
            if (err.response.status === 400) {
                message.open({
                    key,
                    type: "error",
                    content: err.response,
                });
            }
        });
    }

    const realDate = new Date();
    const format = 'HH:mm';


    return (
        <>
            <h2 className="text-2xl mb-3 mt-0">• Calendario Apertura settimanale</h2>
            <Card extra={"items-left w-full p-[16px] bg-cover "}>
                
                <Form form={form} layout="vertical">
                    
                    <div className="">
                        
                        <div className="w-full relative">
                            <div className="relative max-w-[250px] mr-3">
                                <Form.Item
                                    label="Giorno della settimana"
                                    required
                                    name="giorno"
                                    className="mb-0"
                                    tooltip="Questo campo è obbligatorio"
                                >
                                    <Select
                                        onChange={handleChange}
                                        className="mb-2 w-full"
                                        options={[
                                            {
                                                value: 'lunedi',
                                                label: 'Lunedì',
                                            },
                                            {
                                                value: 'martedi',
                                                label: 'Martedì',
                                            },
                                            {
                                                value: 'mercoledi',
                                                label: 'Mercoledì',
                                            },
                                            {
                                                value: 'giovedi',
                                                label: 'Giovedì',
                                            },
                                            {
                                                value: 'venerdi',
                                                label: 'Venerdì',
                                            },
                                            {
                                                value: 'sabato',
                                                label: 'Sabato',
                                            },
                                            {
                                                value: 'domenica',
                                                label: 'Domenica',
                                            }
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="w-full flex mb-2 items-center">
                            <div className="relative mr-3">
                                <Form.Item
                                    label="Sono Chiuso"
                                    name="chiuso"
                                    className="mb-0"
                                >
                                    <Switch onChange={(e) => { 
                                        setClose(e);
                                        }} className="bg-gray-500" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="w-full flex mb-2 items-center">
                            
                            <div className="relative mr-3">
                                <Form.Item
                                    label="Apertura primo turno"
                                    name="ora_apert"
                                    className="mb-0"
                                >
                                    <TimePicker disabled={close} format={format} />
                                </Form.Item>
                            </div>
                            <div className="relative mr-3 grid">
                                <Form.Item
                                    label="Chiusura primo turno"
                                    name="ora_chius"
                                    className="mb-0"
                                >
                                    <TimePicker disabled={close} format={format} />
                                </Form.Item>
                                
                            </div>
                        </div>
                        <div className="w-full flex mb-2">
                            
                            <div className="relative mr-3 grid">
                                <Form.Item
                                    label="Apertura secondo turno"
                                    name="ora_apert_sec_turno"
                                    className="mb-0"
                                >
                                    <TimePicker disabled={close} format={format} />
                                </Form.Item>
                            </div>
                            <div className="relative mr-3 grid">
                                <Form.Item
                                    label="Chiusura secondo turno"
                                    name="ora_chius_sec_turno"
                                    className="mb-0"
                                >
                                    <TimePicker disabled={close} format={format} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="relative mt-4">
                            <Button
                                onClick={handleSubmit}
                                className="bg-brand-500 hover:!bg-brand-600"
                                disabled={day.length === 0}
                                type="primary"
                            >
                                Aggiungi
                            </Button>
                        </div>
                    </div>

                </Form>

                <div className="mt-6">
                    <h2>Calendario settimanale</h2>
                    <List
                        className="demo-loadmore-list"
                        itemLayout="horizontal"
                        dataSource={list.length == 0 ? [] : list}
                        renderItem={(item) => (
                            <List.Item
                                actions={[<a onClick={() => handleRemove(item._id)} key="list-loadmore-more"><Trash /></a>]}
                            >
                                <Skeleton title={false} loading={item.loading} active>
                                    <List.Item.Meta
                                        title={(
                                            <span className="">{item.giorno}</span>
                                        )}
                                        description={
                                            <> 
                                                {item.chiuso ? 
                                                    <span className="text-red-500">Chiuso</span> 
                                                :
                                                    item.ora_apert != undefined ? 
                                                        "dalle " + getTime(item.ora_apert) : ""}
                                                {
                                                    item.ora_chius != undefined ? " fino a " + getTime(item.ora_chius) : ""
                                                }
                                                {
                                                    item.ora_apert_sec_turno != undefined ? " dalle " + getTime(item.ora_apert_sec_turno) : ""
                                                }
                                                {
                                                    item.ora_chius_sec_turno != undefined ? " alle " + getTime(item.ora_chius_sec_turno) : ""
                                                }
                                            </>
                                        }
                                    />
                                </Skeleton>
                            </List.Item>
                        )}
                    />
                </div>
            </Card>
        </>
    );
};

export default Calendar;
