import { gql,useQuery, useMutation } from '@apollo/client';



export function Login(){
    
    return useMutation(gql`mutation Mutation($loginInput: LoginInput) {
            clientLogIn(LoginInput: $loginInput) {
              token
              active
              _id
            }
          }`);

}