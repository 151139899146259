import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { ConfigProvider, theme } from "antd";
import {Client} from './connect.apollo';
import { ApolloProvider } from '@apollo/client';

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ConfigProvider theme={{ token: { algorithm: theme.darkAlgorithm } }}>
     <ApolloProvider client={Client}>
        <BrowserRouter >
          <App />
        </BrowserRouter>
     </ApolloProvider>
  </ConfigProvider>
);
