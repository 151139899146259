import {
  Image,
  Button,
  Form,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Input,
  Tooltip,
  Select,
} from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { message } from "antd";
import {
  PencilSimple,
  Trash,
  UploadSimple,
  X,
  CheckSquare,
  XSquare,
} from "@phosphor-icons/react";
import Modal from "./components/modalAddImage";
import { Link } from "react-router-dom";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  catm,
  index,
  children,
  ...restProps
}) => {
  let inputNode;

  if (inputType === "number") {
    inputNode = <InputNumber />;
  } else if (inputType === "numberPrice") {
    inputNode = (
      <InputNumber
        formatter={(value) =>
          `${value} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
      />
    );
  } else if (inputType === "object") {
    inputNode = (
      <Select
        showSearch
        required
        style={{
          width: 120,
        }}
        options={catm}
      />
    );
  } else {
    inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Gallery = () => {
  const key = "updatable";
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [catm, setCatm] = useState([]);

  const fetchData = () => {
    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_END_POINT_API,
        {
          query: {
            menusMany: [
              "name",
              "price",
              "ingredients",
              "gluten_free",
              "lactose_free",
              "allergens",
              "photo",
              "Show",
              "cat_menu",
            ],
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-api-token":
              "d2e40437bd68442ae11195c3b515593fb1cdb9a555762131ff2c563fc857c0e4",
            "cache-ctr": "6489e5ca4491d151d48b4ec5",
          },
        }
      )
      .then((response) => {
        if (response.data) {
          setData(response.data.models.menus);
          setLoading(false);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: 200,
              // 200 is mock data, you should read it from server
              // total: data.totalCount,
            },
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 400) {
          message.open({
            key,
            type: "error",
            content: "Token d'accesso errato!",
          });
        }
      });

    const key_load_cat_menu = "load_cat_menu";
    let lm = [];
    if (catm.length == 0) {
      axios
        .post(
          process.env.REACT_APP_END_POINT_API,
          {
            query: {
              cat_menusMany: ["name"],
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-api-token":
                "d2e40437bd68442ae11195c3b515593fb1cdb9a555762131ff2c563fc857c0e4",
              "cache-ctr": "6489e5ca4491d151d48b4ec5",
            },
          }
        )
        .then((response) => {
          response.data.models.cat_menus.forEach((element, i) => {
            lm[i] = {
              value: element._id + "|" + element.name,
              label: element.name,
            };
          });
          setCatm(lm);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            message.open({
              key_load_cat_menu,
              type: "error",
              content: "Token d'accesso errato!",
            });
          }
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record._id === editingKey;

  const handleDelete = (key) => {
    const newData = data.filter((item) => item._id !== key._id);

    const key_modal = "updatable";

    axios
      .post(
        process.env.REACT_APP_END_POINT_API,
        {
          query: {},
          mutation: {
            menusByIdRemove: {
              id: key._id,
            },
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-api-token":
              "d2e40437bd68442ae11195c3b515593fb1cdb9a555762131ff2c563fc857c0e4",
            "cache-ctr": "6489e5ca4491d151d48b4ec5",
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data) {
          if (response.data.error) {
            message.open({
              key_modal,
              type: "error",
              content: response.data.error,
            });
          } else {
            message.open({
              key_modal,
              type: "success",
              content: "Menù eliminato",
            });
            setData(newData);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.open({
            key_modal,
            type: "error",
            content: "Token d'accesso errato!",
          });
        }
      });
  };

  const columns = [
    {
      title: "",
      dataIndex: "operation",
      inputType: "text",
      width: "100px",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="flex">
            <Typography.Link
              onClick={() => save(record._id)}
              style={{
                marginRight: 8,
              }}
            >
              <UploadSimple size={20} />
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <X size={20} />
            </Popconfirm>
          </span>
        ) : (
          <span className="flex">
            <Typography.Link
              className="mr-2"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <PencilSimple size={20} />
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <Trash color="red" size={20} />
            </Popconfirm>
          </span>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      inputType: "text",
      editable: true,
    },
    {
      title: "Descrizione",
      dataIndex: "desc",
      inputType: "text",
      editable: true,
    },
    {
      title: "TaGS",
      dataIndex: "TAGS",
      inputType: "text",
      editable: true,
      render: (_, record) => {
        return _ == "null" ? "-" : _;
      },
    },
    {
      title: "Public",
      dataIndex: "public",
      editable: true,
      inputType: "boolean",
      render: (_, record) => {
        return _ && _ == "true" ? (
          <Tooltip placement="left" title={"Visibile"}>
            <CheckSquare size={20} />{" "}
          </Tooltip>
        ) : (
          <Tooltip placement="left" title={"Non visibile"}>
            <XSquare size={20} />
          </Tooltip>
        );
      },
    },
  ];

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      ...record,
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();

      console.log(row.cat_menu);
      if (row.cat_menu._id) {
        message.open({
          type: "error",
          content: "Devi compilare tutti i campi!",
        });
        return;
      }
      const newData = [...data];
      const index = newData.findIndex((item) => key === item._id);

      if (index > -1) {
        const item = newData[index];
        if (!row.cat_menu._id) {
          const cat_menu_split = row.cat_menu.split("|");
          row.cat_menu = {
            _id: cat_menu_split[0],
            name: cat_menu_split[1],
          };
        }

        newData.splice(index, 1, {
          ...item,
          ...row,
        });

        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }

      const key_popup = "updatable";
      console.log(row);
      axios
        .post(
          process.env.REACT_APP_END_POINT_API,
          {
            query: {},
            mutation: {
              menusByIdUpdate: {
                id: key,
                keys: {
                  name: row.name,
                  price: row.price,
                  ingredients: row.ingredients,
                  gluten_free: row.gluten_free,
                  lactose_free: row.lactose_free,
                  allergens: row.allergens,
                  photo: row.photo,
                  Show: row.Show,
                  cat_menu: row.cat_menu._id,
                },
              },
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-api-token":
                "d2e40437bd68442ae11195c3b515593fb1cdb9a555762131ff2c563fc857c0e4",
              "cache-ctr": "6489e5ca4491d151d48b4ec5",
            },
          }
        )
        .then((response) => {
          if (response.data) {
            if (response.data.error) {
              message.open({
                key_popup,
                type: "error",
                content: response.data.error,
              });
            } else {
              message.open({
                key_popup,
                type: "success",
                content: "Menù aggiornato!",
              });
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            message.open({
              key_popup,
              type: "error",
              content: "Token d'accesso errato!",
            });
          }
        });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        catm: catm,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const [modalShow, setModalShow] = useState(false);

  const modalok = (name) => {
    //setModalShow(!modalShow);
    const newData = {
      key: data.length,
      name: name,
    };
    setData([...data, newData]);
  };

  const modalShow_ = () => {
    setModalShow(!modalShow);
  };

  return (
    <div className="mt-5 grid h-full grid-cols-1 gap-5">
      <div className="grid grid-cols-1 grid-rows-1 gap-5">
        <div>
          <Button
            onClick={modalShow_}
            className="bg-indigo-700 hover:!bg-indigo-800"
            type="primary"
          >
            Aggiungi Immagine
          </Button>
        </div>

        <div>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              columns={mergedColumns}
              rowKey={(record) => record._id}
              dataSource={data}
              /*pagination={tableParams.pagination}*/
              loading={loading}
              onChange={handleTableChange}
              rowClassName="editable-row"
              pagination={{
                onChange: cancel,
              }}
            />
          </Form>
        </div>
      </div>
      <Modal show={modalShow} modalShow={modalShow_} ok={modalok} />
    </div>
  );
};

export default Gallery;
