import { Form, Input, Modal, Select, Upload, Button } from "antd";
import { useState } from "react";
import { X, Check,Info} from "@phosphor-icons/react";

import axios from "axios";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message } from "antd";

const App = (props) => {
    const [form] = Form.useForm();
    const [name, setName] = useState();
    const [ref_menu, setRefMenu] = useState();
    const [photoUrl, setPhotoUrl] = useState("");
    const [loadingLogo, setLoadingLogo] = useState(false);
    const [logoUrl, setLogo] = useState()


    const handleOk = () => {
        
        const key = "updatable";

        if(name === undefined || name == ""){
            message.open({
                type: "error",
                content: "Inserisci un nome categoria",
            });
            return;
        }

        if(ref_menu === undefined || ref_menu == ""){
            message.open({
                type: "error",
                content: "Devi inserire un menù di riferimento",
            });
            return;
        }

        
        axios
        .post(
            process.env.REACT_APP_END_POINT_API,
            {
            query: {},
            mutation: {
                cat_menusCreate: {
                    keys: {
                        createAt: new Date(),
                        updateAt: null,
                        nome: name,
                        ref_menu: ref_menu,
                        utente: JSON.parse(localStorage.getItem('tk')).user._id,
                        image: photoUrl
                    },
                },
            },
            },
            {
            headers: {
                "Content-Type": "application/json",
                "auth-api-token":"ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
                "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
            },
            }
        )
        .then((response) => {
            if (response.data) {
                if (response.data.error) {
                    message.open({
                        key,
                        type: "error",
                        content: response.data.error,
                    });
                } else {
                    message.open({
                        key,
                        type: "success",
                        content: "Menù aggiunto!",
                    });
                    props.ok(name);
                    removeImage();
                    form.setFieldsValue({
                        name: '',
                        ref_menu: ''
                    });
                    props.modalShow();
                }
            }
        })
        .catch((err) => {
            if (err.response.status === 400) {
                message.open({
                    key,
                    type: "error",
                    content: "Token d'accesso errato!",
                });
            }
        });
    };
    const handleCancel = () => {
        props.modalShow();
        form.setFieldsValue({
            name: '',
            ref_menu: ''
        });
    };

    const onRequiredTypeChange = ({ name,  ref_menu}) => {
        if(name != undefined) setName(name);
        if(ref_menu != undefined) setRefMenu(ref_menu);
        
    };

    let menuList = [];

    props.dataMenu.map((e) => {
        menuList.push({
            value: e._id,
            label: e.nome,
        });
    });

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };
    
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoadingLogo(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            setPhotoUrl(info.file.response.imageUrl);
    
            getBase64(info.file.originFileObj, (url) => {
                setLoadingLogo(false);
                setLogo(url);
            });
        }
    };
    const removeImage = () => {
      setPhotoUrl("");
      setLogo("");
    }
    const uploadButton = (
        <div>
            {loadingLogo  ? <LoadingOutlined /> : <PlusOutlined />}
            
            <div
                style={{
                marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    
    return (
        <Modal
            okButtonProps={{
                className: "bg-indigo-700 hover:!bg-indigo-800",
            }}
            title="Aggiungi categoria menù"
            className="!rounded-lg md:!px-24 !px-5 !w-full !top-16 "
            classNames={{
                mask: "!bg-gray-600",
                header: "!bg-lightPrimary ml-4",
                content: "!bg-lightPrimary"
            }}
            open={props.show}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Form
                form={form}
                layout="vertical"
                onValuesChange={onRequiredTypeChange}
                className="mt-4 p-3"
                initialValues={{
                    remember: false,
                }}
                autoComplete="off"
            >


                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 md:col-span-7">
                        <div className="bg-white rounded-lg shadow">
                            <div className="py-3 px-6 border-b border-gray-200">
                                <div className="text-md font-medium">Informazioni Categoria</div>
                            </div>
                            <div className="p-6">
                                <Form.Item
                                    label="Nome categoria"
                                    name="name"
                                    required
                                    defaultValue={name}
                                    tooltip="Inserisci un nome per la categoria"
                                >
                                    <Input placeholder="Es. Bevande, Dolci, ecc.." defaultValue={name}/>
                                </Form.Item>
                                <Form.Item
                                    label="Foto"
                                    name="pohto"
                                    tooltip="Inserisci l'immagine della tua categoria se esistente"
                                >
                                    <div className="relative inline-flex flex-center">
                                        <Upload
                                            name="image"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            action="https://server.mynuapp.it/upload/image"
                                            beforeUpload={beforeUpload}
                                            onChange={handleChange}
                                        >
                                            {logoUrl ? (
                                            <img
                                                src={logoUrl}
                                                alt="avatar"
                                                style={{
                                                width: '100%',
                                                }}
                                            />
                                            ) : (
                                                uploadButton
                                            )}
                                        </Upload>
                                        <Button type="text" className="" onClick={removeImage} disabled={!logoUrl} icon={<X className="top-[2px] relative" />}>Rimuovi immagine</Button>
                                    </div>
                                    {/*<Input placeholder="url: http://..." onChange={handlePhotoUrl} />*/}
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-5">
                        <div className="bg-white rounded-lg shadow mb-4">
                            <div className="py-3 px-6 border-b border-gray-200">
                                <div className="text-md font-medium">Riferimenti</div>
                            </div>
                            <div className="p-6">
                                <Form.Item
                                    label="Menù di riferimento"
                                    name="ref_menu"
                                    required
                                    tooltip="Inserisci un menù di riferimento"
                                >
                                    <Select
                                        defaultValue={ref_menu} 
                                        options={menuList}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </div>

            </Form>
        </Modal>
    );
};
export default App;
