import { Form, Input, Modal, InputNumber, message, Switch, Select,Upload, Checkbox, Button} from "antd";
import { useState, useEffect } from "react";
import { X, Check } from "@phosphor-icons/react";
import axios from "axios";
import Tags from "components/tags";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { IoReturnUpForwardOutline } from "react-icons/io5";
import { UpdateArticle } from "views/admin/GraphQl/Articles";
import { gql } from '@apollo/client';

const { TextArea } = Input;


const App = (props) => {
  const [updateArticle]= UpdateArticle();


  const [form] = Form.useForm();
  const [name, setName] = useState(props.data.name);
  const [price, setPrice] = useState(props.data.price);
  const [note, setNote] = useState(props.data.note);
  const [ingredients, setIngredients] = useState(props.data.ingredients);
  const [gluten, setGluten] = useState(props.data.gluten_free);
  const [lactose, setLactose] = useState(props.data.lactose_free);
  const [frozen, setFrozen] = useState(props.data.frozen);
  const [vegan_ok, setVeganOk] = useState(props.data.vegan_ok);
  const [vegetarian_ok, setVegetarianOk] = useState(props.data.vegetarian_ok);
  
  const [prodottoFresco, setProdottoFresco] = useState(props.data.prodotto_fresco_active);

  const allergensArray = props.data.allergens.map(allergenIds => {
    return allergenIds
  });
  const [allergens, setAllergens] = useState(allergensArray);
  const [show, setShow] = useState(props.data.Show);
  const [category, setCategory] = useState(props.data.cat_menu._id);
  const [photoUrl, setPhotoUrl] = useState(props.data.photo);

  const [loadingLogo, setLoadingLogo] = useState(false);
  const [logoUrl, setLogo] = useState(props.data.photo)
  const [catm, setCatm]  =useState(props.catm)
  const [menuss, setMenuss] = useState(props.data.cat_menu.ref_menu);
  const [menus, setMenus] = useState(props.menus);

   // Utilizza useEffect per sincronizzare il valore con props.data.name
 useEffect(() => {
  setName(props.data.name);
}, [props.data.name]);
useEffect(() => {
  setPrice(props.data.price);
}, [props.data.price]);
useEffect(() => {
  setNote(props.data.note);
}, [props.data.note]);
useEffect(() => {
  setIngredients(props.data.ingredients);
}, [props.data.ingredients]);
useEffect(() => {
  setGluten(props.data.gluten);
}, [props.data.gluten]);
useEffect(() => {
  setVeganOk(props.data.vegan_ok);
}, [props.data.gluten]);
useEffect(() => {
  setVegetarianOk(props.data.vegetarian_ok);
}, [props.data.gluten]);
useEffect(() => {
  setLactose(props.data.lactose);
}, [props.data.lactose]);
useEffect(() => {
  setFrozen(props.data.frozen);
}, [props.data.frozen]);
useEffect(() => {
  setProdottoFresco(props.data.prodotto_fresco_active);
}, [props.data.prodotto_fresco_active]);
useEffect(() => {
  const allergensArray = props.data.allergens.map(allergenIds => {  
    return allergenIds
  });
  setAllergens(allergensArray);
}, [props.data.allergens]);
useEffect(() => {
  setShow(props.data.show);
}, [props.data.show]);
useEffect(() => {
  setCategory(props.data.cat_menu._id);
}, [props.data.cat_menu._id]);
useEffect(() => {
  setPhotoUrl(props.data.photo);
}, [props.data.photo]);
useEffect(() => {
  setLogo(props.data.photo);
}, [props.data.photo]);

// useEffect(() => {
//   const catm_ = props.catm.map(item => {  
//     return { value: item._id,
//             label: item.nome
//           }
//   });
//   setCatm(catm_);
// }, [props.catm])

useEffect(() => {
  const filter = props.menus.filter(e => e._id === menuss)
  
  if(filter.length === 0) return

  const catm_ = filter[0].cat_menu.map(item => {  
    return { value: item._id,
            label: item.nome
          }
  });

  setCatm(catm_);
}, [menuss])

useEffect(() => {
  
  const menus_ = props.menus.map(item => {  
    return { value: item._id,
            label: item.nome
          }
  });
  setMenus(menus_);
}, [props.menus])



  const handleOk = () => {
    const key = "add";
    
    console.log(note);

    if (name.length <= 0 ||
      category.length <= 0
    ) {
      message.open({
        key,
        type: "error",
        content: "Completa tutti i campi!",
      });
      return;
    }

    let flatArrIngredients = ingredients.flat();

    const allergens_dec = allergens.map(allergenIds => {  
      return allergenIds._id
    });

    updateArticle(
    {
      variables: {
        updateArticle: {
          "_id": props.data._id,
          "ingredients": flatArrIngredients,
          "name": name,
          "price": price,
          "gluten_free": gluten,
          "lactose_free": lactose,
          "vegan_ok": vegan_ok,
          "vegetarian_ok": vegetarian_ok,
          "allergens": allergens_dec,
          "photo": photoUrl,
          "frozen": frozen,
          "Show": show,
          "cat_menu": category,
          "note": note,
          "prodotto_fresco_active" : prodottoFresco
        }
      }
    }
  ).then(({data}) => {
      if (data) {
        if (data.error) {
          message.open({
            key,
            type: "error",
            content: data.error,
          });
        } else {
          message.open({
            key,
            type: "success",
            content: "Articolo aggiornato!",
          });
          props.modalShow();
          form.resetFields();
          props.ok({
            _id: props.data._id,
            name: name,
            price: price,
            ingredients: ingredients.join(","),
            gluten_free: gluten,
            lactose_free: lactose,
            vegan_ok: vegan_ok,
            vegetarian_ok: vegetarian_ok,
            allergens: allergens,
            Show: show,
            cat_menu: category.split("|")[0],
            frozen: frozen,
            note: note
          });
        }
      }
    })
    .catch((err) => {
      // setLoading(false);
      if (err.response.status === 400) {
        message.open({
          key,
          type: "error",
          content: "Token d'accesso errato!",
        });
      }
    });

// return

//     axios
//       .post(
//         process.env.REACT_APP_END_POINT_API,
//         {
//           query: {},
//           mutation: {
//             articolisByIdUpdate: {
//                 id: props.data._id,
//               keys: {
//                 name: name,
//                 price: price,
//                 ingredients: flatArrIngredients,
//                 gluten_free: gluten,
//                 lactose_free: lactose,
//                 allergens: allergens,
//                 photo: photoUrl,
//                 Show: show,
//                 cat_menu: category.split("|")[0],
//                 frozen: frozen,
//                 utente: JSON.parse(localStorage.getItem("tk"))._id,
//                 note: note
//               },
//             },
//           },
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             "auth-api-token":
//             "ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
//           "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
//           },
//         }
//       )
//       .then((response) => {
//         if (response.data) {
//           if (response.data.error) {
//             message.open({
//               key,
//               type: "error",
//               content: response.data.error,
//             });
//           } else {
//             message.open({
//               key,
//               type: "success",
//               content: "Articolo aggiornato!",
//             });
//             props.modalShow();
//             form.resetFields();
//             props.ok({
//               _id: props.data._id,
//               name: name,
//               price: price,
//               ingredients: ingredients.join(","),
//               gluten_free: gluten,
//               lactose_free: lactose,
//               allergens: allergens,
//               Show: show,
//               cat_menu: category.split("|")[0],
//               frozen: frozen,
//               note: note
//             });
//           }
//         }
//       })
//       .catch((err) => {
//         if (err.response.status === 400) {
//           message.open({
//             key,
//             type: "error",
//             content: "Token d'accesso errato!",
//           });
//         }
//       });
  };
  const handleCancel = () => {
    props.modalShow();
  };

  const handleName = (e) => {
    const val = e.target.value;
    setName(val);
  };

  const handleVisibility = (e) => {
    setShow(e);
  };
  const handleTags = (e) => {
    
    // Verifica se il valore è già presente nell'array checkedValues
    if (allergens.filter(a => a._id === e._id).length > 0) {
      // Se è presente, rimuovilo dall'array
      setAllergens(allergens.filter((value) =>value._id != e._id));
    } else {
      // Se non è presente, aggiungilo all'array
      setAllergens([...allergens, e]);
    }
    
  };
  const handleIngredients = (e) => {
    // Create a copy of the array
    //const newArray = [...ingredients];
    // Add the new element to the copy
    //newArray.push(e);
    // Update the state with the new array

    setIngredients(e);
  };
  const handleFrozen = (e) =>{
    setFrozen(e);
  }
  const handleProdottoFresco = (e) => {
    setProdottoFresco(e);
  }
  const handleGlutenFree = (e) => {
    setGluten(e);
  };
  const handleLactoseFree = (e) => {
    setLactose(e);
  };

  const handleVeganFree = (e) => {
    setVeganOk(e);
  };
  const handleVegetarianFree = (e) => {
    setVegetarianOk(e);
  };

  
  const handlePriceChange = (e) => {
    setPrice(e);
  };
  const handlePhotoUrl = (e) => {
    const val = e.target.value;
    setPhotoUrl(val);
  };
  const handleSelect = (e) => {
    setCategory(e);
  };
  const menusSelect = (e) => { 
    setMenuss(e);
  };

  const handleNote = (e) => {
    const val = e.target.value;
    setNote(val);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('Puoi caricare solo file JPG/PNG!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Immagine troppo grande 2MB!');
    }
    return isJpgOrPng && isLt2M;
};
const removeImage = () => {
  setPhotoUrl("");
  setLogo("");
}

const handleChange = (info) => {
    if (info.file.status === 'uploading') {
        setLoadingLogo(true);
        return;
    }
    if (info.file.status === 'done') {
        // Get this url from response in real world.
        setPhotoUrl(info.file.response.imageUrl);

        getBase64(info.file.originFileObj, (url) => {
            setLoadingLogo(false);
            setLogo(url);
        });
    }
};


const uploadButton = (
    <div>
        {loadingLogo  ? <LoadingOutlined /> : <PlusOutlined />}
        
        <div
            style={{
            marginTop: 8,
            }}
        >
            Upload
        </div>
    </div>
);

form.setFieldsValue({ 
  name: name ,
  price: price ,
  ingredients: ingredients ,
  gluten: gluten ,
  lactose: lactose ,
  vegan_ok: vegan_ok ,
  vegetarian_ok: vegetarian_ok ,
  frozen: frozen ,
  allergens: allergens ,
  show: show ,
  category: category ,
  photoUrl: photoUrl ,
  logoUrl: logoUrl 
});


  return (
    <Modal
      okButtonProps={{
        className: "bg-indigo-700 hover:!bg-indigo-800",
      }}
      title="Modifica articolo"
      className="!rounded-lg md:!px-24 !px-5 !w-full !top-16 "
      classNames={{
        mask: "!bg-gray-600",
        header: "!bg-lightPrimary ml-4",
        content: "!bg-lightPrimary"
      }}
      open={props.show}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        /*onValuesChange={onRequiredTypeChange}*/
        className="mt-4 p-3"
      >
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-7">
            <div className="bg-white rounded-lg shadow">
              <div className="py-3 px-6 border-b border-gray-200">
                <div className="text-md font-medium">Informazioni Prodotto</div>
              </div>
              <div className="p-6">
                <Form.Item
                  label="Nome Prodotto"
                  name="name"
                  required
                  tooltip="Inserisci un nome a questo prodotto"
                  
                >
                
                  <Input onChange={handleName} defaultValue={name} placeholder="Es. Frittata e zucchine" />
                </Form.Item>
                <Form.Item
                  label="Foto"
                  name="pohto"
                  tooltip="Inserisci il link della foto di presentazione del tuo prodotto"
                >
                  <div className="relative inline-flex flex-center">
                    <Upload
                        name="image"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://server.mynuapp.it/upload/image"
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                    >
                        {logoUrl ? (
                        <img
                            src={logoUrl}
                            alt="avatar"
                            style={{
                            width: '100%',
                            }}
                        />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                    <Button type="text" className="" onClick={removeImage} disabled={!logoUrl} icon={<X className="top-[2px] relative" />}>Rimuovi immagine</Button>
                  </div>
                  {/*<Input placeholder="url: http://..." onChange={handlePhotoUrl} />*/}
                </Form.Item>
                <Form.Item
                  label="Note Prodotto"
                  name="note"
                  tooltip="Inserisci delle note a questo prodotto"
                >
                  <TextArea
                    defaultValue={note}
                    onChange={handleNote}
                    autoSize={{
                      minRows: 2,
                      maxRows: 6,
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Ingredienti"
                  name="ingredients"
                  tooltip="Inserisci in modo dettagliato tutti gli ingredienti che contiene questo prodotto"
                >
                  <Tags tags={handleIngredients} tagsValue={ingredients} />  
                </Form.Item>
                <Form.Item
                  label="Allergeni"
                  name="allergens"
                  tooltip="Inserisci gli allergeni che può contenere il tuo prodotto"
                >
                  {/*<Tags tags={handleTags} />*/}
                  {props.allergens.map((item) =>{ 
                    return <Checkbox key={item._id} name="tags[]" checked={allergens.filter(e => e._id === item._id).length > 0} value={item._id}  onChange={() => handleTags(item)}>{item.title}</Checkbox>;
                  })}
                </Form.Item>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow mt-4">
              <div className="py-3 px-6 border-b border-gray-200">
                <div className="text-md font-medium">Prezzo Prodotto</div>
              </div>
              <div className="p-6">
                <Form.Item
                  label="Prezzo in €"
                  name="price"
                  required
                  tooltip="Inserisci il prezzo di questo prodotto"
                >
                  <InputNumber
                    defaultValue={price}
                    step={0.01}
                    className="w-full"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={handlePriceChange}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-5">
            <div className="bg-white rounded-lg shadow mb-4">
              <div className="py-3 px-6 border-b border-gray-200">
                <div className="text-md font-medium">Categoria Prodotto</div>
              </div>
              <div className="p-6">
                <Form.Item
                  label="Menù"
                  name="menu"
                  
                  required
                  tooltip="Inserisci la il menù d'appartenenza"
                >
                  <Select
                    onChange={menusSelect}
                    showSearch
                    required
                    defaultValue={menuss}
                    options={menus}
                  />
                  
                </Form.Item>
                <Form.Item
                  label="Categoria menù"
                  name="cat_menu"
                  required
                  tooltip="Inserisci la categoria d'appartenza del menù"
                >
                  <Select
                    defaultValue={category}
                    onChange={handleSelect}
                    showSearch
                    required
                    disabled={!menuss}
                    options={catm}
                  />
                </Form.Item>



                
               
              </div>
            </div>
            <div className="bg-white rounded-lg shadow ">
              <div className="py-3 px-6 border-b border-gray-200">
                <div className="text-md font-medium">Toggle Prodotto</div>
              </div>
              <div className="p-6">
                <Form.Item
                  label="Senza glutine"
                  name="glutenfree"
                  tooltip="Questo prodotto è senza glutine?"
                >
                  <Switch
                    onChange={handleGlutenFree}
                    defaultChecked={gluten}
                    className="bg-gray-300"
                    checkedChildren={<Check className="mt-[2px]" size={16} />}
                    unCheckedChildren={<X className="mt-[1px]" size={16} />}
                  />
                </Form.Item>
                <Form.Item
                  label="Senza lattosio"
                  name="lactosefree"
                  tooltip="Questo prodotto è senza lattosio?"
                >
                  <Switch
                    onChange={handleLactoseFree}
                    defaultChecked={lactose}
                    className="bg-gray-300"
                    checkedChildren={<Check className="mt-[2px]" size={16} />}
                    unCheckedChildren={<X className="mt-[1px]" size={16} />}
                  />
                </Form.Item>
                
                <Form.Item
                  label="Prodotto Vegano"
                  name="vegan_ok"
                  tooltip="Questo prodotto è vegano?"
                >
                  <Switch
                    onChange={handleVeganFree}
                    defaultChecked={vegan_ok}
                    className="bg-gray-300"
                    checkedChildren={<Check className="mt-[2px]" size={16} />}
                    unCheckedChildren={<X className="mt-[1px]" size={16} />}
                  />
                </Form.Item>

                <Form.Item
                  label="Prodotto Vegetariano"
                  name="vegetarian_ok"
                  tooltip="Questo prodotto è vegetariano?"
                >
                  <Switch
                    onChange={handleVegetarianFree}
                    defaultChecked={vegetarian_ok}
                    className="bg-gray-300"
                    checkedChildren={<Check className="mt-[2px]" size={16} />}
                    unCheckedChildren={<X className="mt-[1px]" size={16} />}
                  />
                </Form.Item>

                <Form.Item
                  label="Prodotto congelato"
                  name="frozen"
                  tooltip="Questo prodotto è stato congelato?"
                >
                    <Switch
                        onChange={handleFrozen}
                        defaultChecked={frozen}
                        className="bg-gray-300"
                        checkedChildren={<Check className="mt-[2px]" size={16} />}
                        unCheckedChildren={<X className="mt-[1px]" size={16} />}
                    />
                </Form.Item>

                <Form.Item
                  label="Prodotto fresco"
                  name="prodotto_fresco_active"
                  tooltip="Questo è un prodotto fresco?"
                >
                  <Switch
                    onChange={handleProdottoFresco}
                    className="bg-gray-300"
                    checkedChildren={<Check className="mt-[2px]" size={16} />}
                    unCheckedChildren={<X className="mt-[1px]" size={16} />}
                  />
                </Form.Item>

                <Form.Item
                  label="Rendi visibile articolo nel menù"
                  name="show"
                  required
                  tooltip="Scegli se mostrare questo articolo"
                >
                  <Switch
                    onChange={handleVisibility}
                    className="bg-gray-300"
                    checkedChildren={<Check className="mt-[2px]" size={16} />}
                    unCheckedChildren={<X className="mt-[1px]" size={16} />}
                    defaultChecked={show}
                  />
                </Form.Item>

                
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
export default App;
