import CheckTable from "./components/CheckTable";
import {
  Button,
  Form,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Select,
  Upload,
  Input,
} from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { message, Image } from "antd";
import { PencilSimple, Trash, UploadSimple, X, CloudArrowUp  } from "@phosphor-icons/react";
import Modal from "components/modal";
import { Link } from "react-router-dom";


const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  dataMenu,
  ...restProps
}) => {
  let inputNode;
  
  //console.log(inputType);

  if(inputType === "number"){
    inputNode = <InputNumber />;
  }else if(inputType === "array"){


    let menuList = [];

    dataMenu.map((e) => {
        menuList.push({
            value: e._id,
            label: e.nome,
        });
    });
    if(menuList.length > 0){
        inputNode = <Select
            defaultValue={record.ref_menu ? record.ref_menu._id  : ""}
            options={menuList}
        />;
    }else{
        inputNode = <>Null</>;
    }
  }else if(inputType === "image"){
    
    const upD = {
      name: 'image',
      action: 'https://server.mynuapp.it/upload/image',
      maxCount:1,
      listType:"picture",
      headers: {
        //authorization: 'authorization-text',
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
          return info.file.response.imageUrl;
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);

        }
      },
    };
   
    inputNode =<Upload {...upD}>
                <Button icon={<CloudArrowUp className="relative top-[0.2rem]" />}>Click to Upload</Button>
              </Upload>
  }else{
    inputNode = <Input />;
  }


  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};


const Tables = () => {
  const key = "updatable";
  const [data, setData] = useState([]);
  const [dataMenu, setDataMenu] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });



  const fetchData = () => {
    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_END_POINT_API,
        {
            query: {
                cat_menusById: {
                    "id": {
                        "utente": JSON.parse(localStorage.getItem('tk')).user._id,
                    },
                    "keys":[
                        "nome","ref_menu", "order", "image"
                    ]
                },
            },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-api-token":
              "ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
            "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
          },
        }
      )
      .then((response) => {
        if (response.data) {
            
          setData(response.data.models.cat_menus);
          setLoading(false);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: 200,
              // 200 is mock data, you should read it from server
              // total: data.totalCount,
            },
          });

            if(window.location.hash != null){
                const id = window.location.hash.replace("#", "");
                if(document.querySelector("tr[data-row-key='"+id+"']")){
                    document.querySelector("tr[data-row-key='"+id+"']").style.background = "rgb(239 255 0 / 16%)";
                }
            }
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 400) {
          message.open({
            key,
            type: "error",
            content: "Token d'accesso errato!",
          });
        }
      });

      axios
      .post(
        process.env.REACT_APP_END_POINT_API,
        {
            query: {
                menusById: {
                  id: {
                    cliente: JSON.parse(localStorage.getItem('tk')).user._id
                  },
                  keys: ["nome"]
                },
            },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-api-token":
              "ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
            "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
          },
        }
      )
      .then((response) => {
        if (response.data) {
            setDataMenu(response.data.models.menus);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 400) {
          message.open({
            key,
            type: "error",
            content: "Token d'accesso errato!",
          });
        }
      });


    /*axios.post(process.env.REACT_APP_END_POINT_API , {
			"query": {
				"menusMany": [
					"name",
					"price",
					"ingredients",
					"gluten_free",
					"lactose_free",
					"allergens",
					"photo",
					"Show",
					"user"
				]
			}
			},{
			headers: {
				'Content-Type': 'application/json',
				'auth-api-token': "ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
				'cache-ctr': "64c694b0ab6e70e4eeb0cb47"
			}
		}).then((response) => {
			if(response.data){
				setData(response.data.models.menus);
				setLoading(false);
				setTableParams({
					...tableParams,
					pagination: {
						...tableParams.pagination,
						total: 200,
						// 200 is mock data, you should read it from server
						// total: data.totalCount,
					},
				});
			}
		}).catch((err) => {
			setLoading(false);
			if(err.response.status === 400){
				message.open({
					key,
					type: 'error',
					content: 'Token d\'accesso errato!',
				});
			}
		});*/
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record._id === editingKey;

  const handleDelete = (key) => {
    const newData = data.filter((item) => item._id !== key._id);

    const key_modal = "updatable";

    axios
      .post(
        process.env.REACT_APP_END_POINT_API,
        {
          query: {},
          mutation: {
            cat_menusByIdRemove: {
              id: key._id,
            },
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-api-token":
              "ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
            "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
          },
        }
      )
      .then((response) => {
        
        if (response.data) {
          if (response.data.error) {
            message.open({
              key_modal,
              type: "error",
              content: response.data.error,
            });
          } else {
            message.open({
              key_modal,
              type: "success",
              content: "Menù eliminato",
            });
            setData(newData);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.open({
            key_modal,
            type: "error",
            content: "Token d'accesso errato!",
          });
        }
      });
  };

  const columns = [
    {
      title: "",
      dataIndex: "operation",
      width: "100px",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="flex">
            <Typography.Link
              onClick={() => save(record._id)}
              style={{
                marginRight: 8,
              }}
            >
              <UploadSimple size={20} />
            </Typography.Link>
            <Popconfirm title="Vuoi annullare?" onConfirm={cancel}>
              <X size={20} />
            </Popconfirm>
          </span>
        ) : (
          <span className="flex">
            <Typography.Link
              className="mr-2"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <PencilSimple size={20} />
            </Typography.Link>
            <Popconfirm
              title="Vuoi eliminare?"
              onConfirm={() => handleDelete(record)}
            >
              <Trash color="red" size={20} />
            </Popconfirm>
          </span>
        );
      },
    },
    {
      title: "Posizione",
      dataIndex: "order",
      editable: true,
      typeRef: "number",
      render: (_, record) => { 
        return (
          <div
            
          >
            {_}
          </div>
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "nome",
      editable: true,
      typeRef: "text",
      render: (_, record) => {
        return (
          <div
            className={
              window.location.hash == "#" + _.replace(" ", "")
                ? "bg-amber-300/30"
                : ""
            }
          >
            {_}
          </div>
        );
      },
    },
    {
      title: "Cat. menù",
      dataIndex: "ref_menu",
      editable: true,
      typeRef: "array",
      dataMenu:dataMenu,
      render: (_, record) => { 
        if(_ === undefined){
          return (
            <span>
              -
            </span>
          );
        }else 
        return (
          <Link
           to={"/admin/cat_men_#" + _._id}
           style={{color: " rgb(2 132 199)"}}
          >
            {_.nome}
          </Link>
        );
      },
    },
    {
      title: "Immagine",
      dataIndex: "image",
      editable: true,
      typeRef: "image",
      render: (_, record) => { 
        return _ == "null" || _ == undefined ? "-" : <Image width={35} src={_} />;
      },
    },
    
  ];

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      ...record,
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {


    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item._id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
      //console.log(key, row.name);

      const key_popup = "updatable";

      
      axios
        .post(
          process.env.REACT_APP_END_POINT_API,
          {
            query: {},
            mutation: {
              cat_menusByIdUpdate: {
                id: key,
                keys: {
                  nome: row.nome,
                  ref_menu: row.ref_menu,
                  order: row.order,
                  image: row.image.file.response.imageUrl
                },
              },
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-api-token":
                "ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
              "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
            },
          }
        )
        .then((response) => {
          if (response.data) { 
            if (response.data.error) {
              message.open({
                key_popup,
                type: "error",
                content: response.data.error,
              });
            } else {
              message.open({
                key_popup,
                type: "success",
                content: "Menù aggiornato!",
              });
              fetchData();
              //window.location.reload();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            message.open({
              key_popup,
              type: "error",
              content: "Token d'accesso errato!",
            });
          }
        });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.typeRef,
        dataMenu: dataMenu,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const [modalShow, setModalShow] = useState(false);

  const modalok = (name, ref_menu) => {
    //setModalShow(!modalShow);
    /*const newData = {
      key: data.length,
      name: name,
      ref_menu: ref_menu
    };
    setData([...data, newData]);*/
    console.log("ok");
    fetchData();
  };

  const modalShow_ = () => {
    setModalShow(!modalShow);
  };

  return (
    <div className="mt-5 grid h-full grid-cols-1 gap-5">
      <div className="grid grid-cols-1 grid-rows-1 gap-5">
        <div>
          <Button
            onClick={modalShow_}
            className="bg-indigo-700 hover:!bg-indigo-800"
            type="primary"
          >
            Aggiungi Categoria
          </Button>
        </div>

        <div>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              columns={mergedColumns}
              rowKey={(record) => record._id}
              dataSource={data}
              /*pagination={tableParams.pagination}*/
              loading={loading}
              onChange={handleTableChange}
              rowClassName="editable-row"
              pagination={{
                onChange: cancel,
              }}
            />
          </Form>
        </div>
      </div>
      <Modal show={modalShow} modalShow={modalShow_} dataMenu={dataMenu} ok={modalok} />
    </div>
  );
};

export default Tables;
