import React, {useEffect, useState} from 'react';
import Banner from "./components/Banner";
import Accessibility from "./components/Accessibility";
import CloseAccount from "./components/CloseAccount";
import Gallery from './components/Gallery';
import Calendar from './components/Calendar';
import {ClientInfoFull} from 'views/admin/GraphQl/ClientInfo'
import {  message } from "antd";

const ProfileOverview = ({data}) => {
  // const [getClientInfoFull] = ClientInfoFull();
  const key = "init";

  const [banner, setBanner] = useState({});

  useEffect(() => {
    console.log(data);
  
    // getClientInfoFull().then(({data, error, errors}) => { 
    //   if(error || errors){ 
    //     if(errors[0].extensions.code === "EXPIRED_TOKEN"){
    //       localStorage.removeItem('tk');
    //       window.location.href= "/";
    //     }
    //     if(error.graphQLErrors[0].extensions.code === "EXPIRED_TOKEN"){
    //       localStorage.removeItem('tk');
    //       window.location.href= "/";
    //     }
    //     message.open({
    //       key,
    //       type: "error",
    //       content: error.message,
    //     });
    //     return;
    //   }
    //   setBanner(data.clientInfoProtected);
    //   setGallery(data.clientInfoProtected.gallerys);
    // }).catch((error) => {
    //   console.error('Si è verificato un errore:', error);
    //   message.open({
    //     key,
    //     type: "error",
    //     content: error.message,
    //   });
    // });

    setBanner(data);
  }, [data])

  
  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-12 lg:!mb-0">
          <Banner data={banner} />
        </div>
         <div className="col-span-12 lg:!mb-0">
          <Calendar data={banner} />
        </div>
        <div className="col-span-12 lg:!mb-0">
          <Gallery data={banner} />
        </div>
        <div className="col-span-12 lg:!mb-0">
          <Accessibility />
        </div>
        <div className="col-span-12 lg:!mb-0">
          <CloseAccount />
        </div>
      </div>
      {/* all project & ... */}
    </div>
  );
};

export default ProfileOverview;
