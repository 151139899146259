import React, { useState,useEffect } from "react";
import Card from "components/card";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Trash } from "@phosphor-icons/react";

import { Button, Form, Input, Skeleton, message, List, Avatar} from "antd";
import axios from "axios";
import {ClientInfoUpdate} from 'views/admin/GraphQl/ClientInfo';
import {SponsorDelete, SponsorCreate} from 'views/admin/GraphQl/sponsor';
import DragAndDrop from 'components/uploader/dragAndDrop';
import {UploadImage} from 'views/admin/GraphQl/Uploader'

const { TextArea } = Input;

const Partners = (props) => {
    const [updateClientInfo] = ClientInfoUpdate();
    const [sponsorDelete] = SponsorDelete();
    const [sponsorCreate] = SponsorCreate();
    const [upload] = UploadImage();


    
    const [form] = Form.useForm();
    
    const [clientInfo, setClientInfo] = useState({});
    const [sponsor, setSponsor] = useState([]);
    const [sponsorName, setSponsorName] = useState();
    const [sponsorUrl, setSponsorUrl] = useState();
    const [file, setFile] = useState();
    const [resUpload, setResUpload] = useState();
    
    
    const [load, setLoad] = useState(false);
    

    const handleSubmit = async (e) => {
        const key = "init";
       
        
        // if(sponsorUrl.length === 0){

        //     message.open({
        //         key,
        //         type: "error",
        //         content: "Inserisci un Url sponsor valido!",
        //     });

        //     return
        // }

        if(sponsorName.length === 0){

            message.open({
                key,
                type: "error",
                content: "Inserisci un Nome sponsor valido!",
            });

            return
        }


        if(!file){
            message.open({
                key,
                type: "error",
                content: "Inserisci una img per lo sponsor!",
            });

            return
        }

        await upload({ variables: { file },headers: {'x-apollo-operation-name': 'uploadFile', 'Content-Type': 'multipart/form-data'} }).then((data) => {

            // setResUpload(data.data.uploadFile);

            sponsorCreate({
                variables: {
                    storeClientSponsorInput: {
                        sponsor_name: sponsorName,
                        sponsor_src: sponsorUrl,
                        sponsor_image: data.data.uploadFile,
                        sponsor_image_dump: "small-" + data.data.uploadFile,
                        type: "0"
                    },
                }
            }).then(({data}) => {
                //Login avvenuto con successo
                message.open({
                    key,
                    type: "success",
                    content: "Informazioni aggiornate!",
                });
                    
                // const updatedList = clientInfo.sponsor = [...clientInfo.sponsor , data.clientSponsorStore];
    
                // // Aggiorna lo stato con la nuova lista
                // setClientInfo(updatedList);
                window.location.reload();
    
                setClientInfo((prevState) => ({
                    ...prevState,
                    sponsor: [
                        ...prevState.sponsor,
                        data.clientSponsorStore
                    ]
                }));
                
            })
            .catch((error) => {
                console.error('Si è verificato un errore:', error);
                message.open({
                    key,
                    type: "error",
                    content: error.message,
                });
            });
            // onSuccess();
        })
        .catch((error) => {
            message.error('Upload failed.');
            // onError(error);
        });

        
        
    };

    const handleRemove = (item) => {
        const key = "update";

        
        sponsorDelete({
            variables: {
                deleteClientSponsorInput: {
                    _id: item
                }
            }
        }).then(({data}) => {
            //Login avvenuto con successo
            console.log(data);
            message.open({
                key,
                type: "success",
                content: "Informazioni aggiornate!",
            });

            
            const updatedList = clientInfo.sponsor.filter(item_ => item_._id != item);
            // Aggiorna lo stato con la nuova lista

            setClientInfo((prevState) => ({
                ...prevState,
                sponsor: updatedList
            }));
            console.log(clientInfo);

            // upload({ variables: { file },headers: {'x-apollo-operation-name': 'uploadFile', 'Content-Type': 'multipart/form-data'} }).then((data) => {
            //     message.success(`File uploaded: ${data.uploadFile}`);
            // })
            // .catch((error) => {
            //     message.error('Upload failed.');

            // });

            // setLoad(true);
        })
        .catch((error) => {
            console.error('Si è verificato un errore:', error);
            message.open({
                key,
                type: "error",
                content: error.message,
            });
        });
    }

    const onInputChangeName = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setSponsorName(value);
    }

    const onInputChangeImage = (e) => {
        setSponsorUrl(e.target.value);
    }

    const uploadFile = (file, onSuccess, onError) =>{
        
        setFile(file);
    }
    
    useEffect(() => {
        setClientInfo(props.data);
        
        if(props.data.clientInfo) setSponsor(props.data.clientInfo.sponsor);
    }, [props.data])

    
    if(clientInfo['client_type_filter'] === undefined) return;
    
    // clientInfo['client_type_filter'] = [].concat(...clientInfo['client_type_filter']);
    //setClientInfo(clientInfo);
    

    
    return (
        <Card extra={"items-left w-full h-full p-[16px] bg-cover"}>
            <h2 class="mb-6">Imposta i tuoi parteners</h2>
            <Form form={form} onFinish={handleSubmit} layout="vertical">
                
                <div className="mt-6">
                    <List
                        className="demo-loadmore-list"
                        itemLayout="horizontal"
                        dataSource={clientInfo.sponsor.filter(e => e.type == "0").length == 0 ? [] : clientInfo.sponsor.filter(e => e.type == "0")}
                        renderItem={(item) => (
                            <List.Item
                                className="hover:bg-gray-100/50"
                                actions={[<a onClick={() => handleRemove(item._id)} key="list-loadmore-more"><Trash /></a>]}
                            >
                                <Skeleton title={false} loading={item.loading} active>
                                    <List.Item.Meta
                                        avatar={<Avatar className="outline-none" src={"http://localhost:5002/public/images/dump/" + item.sponsor_image_dump} />}
                                        title={(
                                            <span className="font-medium">{item.sponsor_name}</span>
                                        )}
                                        className="outline-none"
                                        description={item.sponsor_src}
                                    />
                                    
                                </Skeleton>
                            </List.Item>
                        )}
                    />
                </div>

                <Form.Item
                    label="Inserisci il nome dello sponsor"
                    tooltip="Inserisci un nome"
                    required
                >
                    <Input
                        name="sponsor_name"
                        placeholder={"Name"}
                        onChange={onInputChangeName}
                    />
                </Form.Item>
                <Form.Item
                    label="Inserisci un link all'immagine"
                    tooltip="Inserisci uno sponsor"
                    
                >
                    <Input
                        name="sponsor_src"
                        placeholder={"Url Sponsor http://...."}
                        onChange={onInputChangeImage}
                    />
                </Form.Item>
                <Form.Item
                    label="Carica una immagine"
                    tooltip="Inserisci uno sponsor"
                    required
                >
                    <DragAndDrop uploadFile={uploadFile} disabled={!sponsorName}/>
                </Form.Item>

                <Form.Item>
                    <Button htmlType="submit"
                        className="bg-brand-500 hover:!bg-brand-600"
                        type="primary"
                    >
                        Aggiungi
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default Partners;
